.carouselSlide{
  height: 665px;
  position: relative;
  .slick-track{
    height: 665px;
  }
}
.carousel-items .item{
  background-repeat: no-repeat;
  background-position: center;
}
.container_whole{
  margin-top: 145px;
  position: relative;
}
.carousel-dots{
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.slick-dots{
  display: flex!important;
  max-width: 1670px;
  //width: 100%;
  margin: 0 auto;
}

.carouselSlide .slick-dots{
  padding: 0;
}
.slick-dots li{
  width: 19px;
  height: 19px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  transition: .3s;
  margin-right: 15px;
  cursor: pointer;
}
.slick-dots li:last-of-type{
  margin-right: 0;
}
.slick-dots li.slick-active{
  border-color: #f4a536;
  background-color: #f4a536;
}
.slick-dots li button{
  border: none;
  font-size: 0;
  background-color: transparent;
}
.carousel-content{
  padding-top: 80px;
  max-width: 1670px;
  margin: 0 auto;
  .carousel-text {
    max-width: 750px;
  }
  .btnWrap {
    margin-top: 36px;
  }
}
.carousel-content h2{
  font-family: ff-tisa-sans-web-pro;
  font-size: 56px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 24px;
  max-width: 1200px;
  line-height: 64px;
}
.carousel-content p{
  font-family: 'Open Sans';
  font-size: 28px;
  color: #ffffff;
  font-weight: 400;
  line-height: 36px;
  max-width: 700px;
}
.btnWrap{
  width: auto;
  height: 80px;
  background-color: #eb9800;
  display: inline-block;
  transition: .3s;
  cursor: pointer;
  margin-top: 60px;
}
.btnWrap a{
  font-family: 'Open Sans';
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  padding-right: 106px;
  padding-left: 36px;
  transition: .3s;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 80px;
  text-align: left;
}
.btnWrap a::before,
.btnWrap a::after{
  content: '';
  position: absolute;
  top: 52%;
  right: 36px;
  transform: translateY(-50%);
}
.btnWrap a::before{
  width: 17px;
  height: 2px;
  background-color: #fff;
}
.btnWrap a::after{
  width: 14px;
  height: 14px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  background-color: transparent;
  transform: translateY(-50%) rotate(45deg);
}
.btnWrap:hover{
  box-shadow: 0 4px 12px rgba(0,0,0,0.5);
  background-color: #F08400;
}
.carouselBtn a{
  padding-left: 36px;
}
.carouselBtn.btnWrap a::before,
.carouselBtn.btnWrap a::after{
  right: 36px;
}

@media (max-width: 1750px) {
  .carousel-content{
    max-width: none;
    padding-left: 60px;
    padding-right: 60px;
  }
  .carouselSlide .slick-dots{
    padding-left: 60px;
  }
}
@media (max-width: 1200px) {
  .item {
    .carousel-content{
      padding-top: 80px;
    }
  }
  .carouselSlide .slick-dots{
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .container_whole {
    margin-top: 135px;
  }
  .carouselSlide{
    height: 720px;
    .slick-track{
      height: 720px;
      .item {
        .carousel-content{
          padding-top: 120px;
          .carousel-text {
            max-width: 700px;
          }
          h2 {
            font-size: 42px;
            line-height: 50px;
          }
          p {
            font-size: 22px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  div.container_whole {
    margin-top: 110px;
  }
  .carouselSlide{
    height: 500px;
    .slick-track{
      height: 500px;
      .item {
        .carousel-content{
          padding-top: 60px;
          h2 {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            line-height: 24px;
          }
          .btnWrap {
            margin-top: 24px;
          }
        }
      }
    }
    .carousel-dots {
      bottom: 60px;
    }
  }
  .btnWrap {
    height: 60px;
    a {
      padding-left: 24px;
      padding-right: 94px;
      font-size: 16px;
      line-height: 60px;
      &:before,
      &:after {
        right: 24px;
      }
    }
  }
}

.welcome{
  position: relative;
}
.welcome__container{
  max-width: 1670px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
}
.welcome__left{
  width: 35%;
}
.welcome__right{
  width: 55%;
  @media (min-width: 1200px) {
    background: none;
  }
}
.welcome__right--bg{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 40%;
}
.welcome__btn{
  background-color: #1e5ba1;
}
.welcome__btn:hover{
  background-color: #073f8c;
}
.welcome__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
}
.welcome__text{
  font-family: 'Open Sans';
  font-size: 20px;
  color: #1e5ba1;
  line-height: 30px;
  margin-bottom: 30px;
}
.welcome__right--wrap{
  position: relative;
}
.welcome__right--wrap h4{
  font-family: 'Open Sans';
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}
.welcome__right--wrap h4::after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 4px;
  background-color: #1e5ba1;
}
.welcome__right--wrap p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
}
.newsEvents{
  background: linear-gradient(21deg, #008591 2%, #004a57 100% );
  overflow: hidden;
}
.newsEvents__container{
  max-width: 1670px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 80px 0 110px;
  .link-main {
    font-family: 'Open Sans';
    display: inline-flex;
    width: 158px;
    height: 60px;
    padding: 0px 24px 0px 25px;
    background-color: #eb9800;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
    margin-left: 10px;
    align-items: center;
    justify-content: space-between;
    transition: all .25s;
    cursor: pointer;
    @media screen and (max-width: 1200px ){
      margin-bottom: 40px;
    }
    &:hover {
      box-shadow: 0px 4px 12px rgba(0,0,0,0.5);
      background-color: #F08400;
    }
  }
}
.newsEvents__container > div{
  width: 30%;
  &.newsEvents__trends {
    order: 1;
  }
}
.newsEvents__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  margin-bottom: 40px;
}
.newsEvents__item{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 10px;
  text-decoration: none;
}
.newsEvents__item:hover{
  background-color: #3599ab;
}
.newsEvents__thumb{
  min-width: 113px;
  height: 113px;
  margin-right: 12px;
  flex: none;
  -webkit-background-size: contain!important;
  background-size: contain!important;
  background-position: center;
  background-color: #fff;
  background-repeat: no-repeat;
}
.newsEvents__info{
  width: 100%;
  word-break: break-all;
  position: relative;
  padding-bottom: 30px;
  overflow: hidden;
}
.newsEvents__info h4 a,
.newsEvents__info .h4{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 7px;
  text-decoration: none;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.newsEvents__info p,
.newsEvents__info span.text{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 9px;
  //height: 22px;
  //white-space: nowrap;
  //overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.newsEvents__trends .newsEvents__info p,
.newsEvents__trends .newsEvents__info span.text,
.newsEvents__news .newsEvents__info p,
.newsEvents__news .newsEvents__info span.text{
  white-space: normal;
  height: auto!important;
  word-break: normal;
  text-overflow: unset;
}
.newsEvents__link{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #fcd95b;
  //font-weight: 800;
  line-height: 25px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.newsEvents__info .post-date,
.newsEvents__info .post-date + span,
.newsEvents__info .post-date + p{
  margin-bottom: 5px;
  word-break: normal;
}
.anniversaries{
  position: relative;
  height: 670px;
}
.anniversaries__container{
  max-width: 1670px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 670px;
}
.anniversaries__slider{
  width: 60%;
}
.anniversaries__history{
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .btnWrap {
    max-width: 310px;
  }
}
.anniversaries__slider--full{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 50%;
}
.anniversaries__item{
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  background-position: center;
}
.anniversaries .slick-track{
  height: 670px;
}
.anniversaries__pagination{
  position: absolute;
  bottom: 45px;
  right: 50px;
}
.anniversaries__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}
.anniversaries__title::after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 18px;
  height: 4px;
  background-color: #1e5ba1;
}
.anniversaries__wrapper .anniversaries__celeb {
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 13px;
  margin-bottom: 5px;
}
.anniversaries__wrapper .ad{
  font-family: Open Sans;
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}
.anniversaries__history p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #1e5ba1;
  line-height: 24px;
  margin-bottom: 40px;
}
.anniversaries__history .anniversaries__desc p{
  margin-bottom: 0;
}
.anniversaries__history .anniversaries__desc p:first-of-type{
  margin-bottom: 30px;
}
.anniversaries__text{
  position: absolute;
  left: 5%;
  bottom: 40px;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 40px;
}
.anniversaries__text .anniversaries__name{
  font-weight: 700;
}
.anniversaries .slick-dots{
  padding: 0;
}
.supporters__container{
  margin: auto;
  padding: 90px 0 100px;
}
.supporters__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
}
.supporters__items .slick-track{
  display: flex;
}
.supporters__items li{
  margin: 0 50px;
}
.supporters__items li img{
  max-width: 100%;
  height: auto;
}
.facebook-slider{
  background: linear-gradient(20deg, #425a9e 1%, #233882 100% );
  padding-top: 80px;
  padding-bottom: 60px;
}
.facebook-slider-list{
  cursor: default;
}
.facebook__container{
  max-width: 1670px;
  margin: auto;
  min-height: 280px;
  position: relative;
}
.facebook-link__icons{
  position: absolute;
  bottom: 20px;
  left: -30px;
  transform: rotate(-90deg);
  font-family: 'Open Sans';
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  transform-origin: 0 0;
  text-decoration: none;
  transition: .3s;
}
.facebook-link__icons:hover{
  color: #F4A536;
}
.facebook-link__icons i{
  font-size: 30px;
  margin-right: 20px;
}
.facebook-slider .swiper-wrapper{
  max-width: 1385px;
  margin: auto;
  transform: none!important;
  position: relative;
}
.facebook-slider-item{
  margin: 0 45px;
  &:hover,
  &:focus {
    .facebook-slider-item__title a,
    .facebook-slider-item__date {
      color: #F4A536;
    }
  }
  @media (max-width: 600px) {
    &:hover{
      .facebook-slider-item__title a,
      .facebook-slider-item__date{
        color: #fff;
      }
    }
  }
}
.facebook-slider-item__img{
  background-size: cover;
  background-position: center;
  height: 180px;
  margin-bottom: 24px;
  position: relative;
}
.fbNews{
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #ffffff;
  font-size: 25px;
}
.facebook-slider-item__title a,
.facebook-slider-item__date{
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  //word-break: break-all;
  transition: all .25s;
}
.facebook-slider-item__title{
  margin-bottom: 15px;
}
.facebook-slider .slick-arrow{
  border: none;
  font-size: 0;
  cursor: pointer;
  padding: 0;
  width: 25px;
  height: 25px;
  border-left: 4px solid #fff;
  border-top: 4px solid #fff;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%) rotate(-45deg);
  transition: all .25s;
  &:hover,
  &:focus {
    border-color: #F4A536;
  }
}
.facebook-slider .slick-next{
  left: auto;
  right: -50px;
  transform: translateY(-50%) rotate(135deg);
}
.facebook-slider .slick-disabled{
  opacity: 0;
  cursor: default;
}
.facebook-slider .swiper-container{
  overflow: visible;
}



@media (max-width: 1750px) {
  .facebook__container,
  .anniversaries__container,
  .supporters__container{
    max-width: none;
    padding: 0 60px;
  }
  .supporters__container{
    max-width: none;
    padding: 60px 0 70px;
  }
  .newsEvents__container{
    max-width: none;
    padding: 75px 110px 100px;
  }
  .welcome__container{
    max-width: none;
    padding: 100px 60px;
  }
  .anniversaries__history{
    width: 35%;
  }
  .anniversaries__slider--full{
    right: 40%;
  }
  .facebook-slider .swiper-wrapper{
    max-width: 85%;
  }
  .facebook-link__icons{
    left: 20px;
  }
}

@media (max-width: 1500px) {
  .anniversaries,
  .anniversaries__container,
  .anniversaries__slider,
  .anniversaries .slick-track{
    height: 530px;
  }
  .newsEvents__container{
    max-width: none;
    padding: 75px 60px 100px;
  }
}

@media (max-width: 1350px) {
  .container_whole{
    margin-top: 125px;
  }
}

@media (max-width: 1200px) {
  .anniversaries{
    height: auto;
  }
  .anniversaries__container{
    flex-direction: column;
    height: auto;
  }
  //.anniversaries__slider,
  //.anniversaries .slick-track{
  //  height: 530px;
  //}
  .anniversaries__slider,
  .anniversaries__history{
    width: 100%;
  }
  .anniversaries__slider--full{
    right: 0;
  }
  .anniversaries__pagination{
    top: 460px;
    right: 30px;
  }
  .anniversaries__history{
    padding: 90px 30px;
    position: relative;
  }
  .newsEvents__container{
    display: flex;
    flex-direction: column;
  }
  .newsEvents__container>div,
  .welcome__left,
  .welcome__right{
    width: 100%;
  }
  .welcome__container{
    flex-direction: column;
    padding: 0;
  }
  .welcome__right--bg{
    left: 0;
    top: 51%;
  }
  .welcome__left{
    padding: 75px 60px;
  }
  .welcome__right{
    padding: 95px 60px;
    background: rgba(32,23,56,.8);
  }
  .carousel-content{
    padding: 160px 60px 0 60px;
  }
  .carousel-dots{
    left: 60px;
    bottom: 70px;
  }
  .welcome__right--bg{
    display: none;
  }
  .anniversaries__btn.btnWrap{
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .carousel-content h2{
    font-size: 42px;
  }
  .carousel-content p{
    font-size: 22px;
  }
  .facebook-slider-item{
    margin: 0 10px;
  }
  .facebook-slider .swiper-wrapper{
    margin: 0 45px 0 75px;
  }
  .anniversaries__wrapper .ad {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .carousel-content h2{
    font-size: 28px;
    line-height: 32px;
  }
  .supporters__items li{
    margin: 0;
  }
}

@media (max-width: 600px) {
  .newsEvents__container{
    padding: 60px 20px;
  }
  .carousel-content p,
  .welcome__text{
    font-size: 16px;
    line-height: 24px;
  }
  .carouselBtn{
    width: auto;
    margin-top: 40px;
  }
  .carousel-content{
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: start;
  }
  .welcome__left{
    padding: 60px 20px 40px;
  }
  .welcome__right{
    padding: 50px 20px 75px;
  }
  .welcome__title{
    font-size: 24px;
    line-height: 32px;
  }
  .welcome__right--wrap p{
    font-size: 14px;
  }
  .welcome__btn{
    margin-top: 35px;
  }
  .anniversaries__container{
    padding: 0 20px;
  }
  .anniversaries__history{
    padding: 40px 0 65px;
  }
  .anniversaries__text,
  .anniversaries__wrapper .ad{
    font-size: 14px;
    line-height: 26px;
  }
  .anniversaries__text{
    margin-bottom: 5px;
  }
  .anniversaries__text .anniversaries__name{
    font-weight: normal;
  }
  .supporters__items li{
    width: 135px;
  }
  .facebook-link__icons{
    transform-origin: 50% 50%;
    left: 50%;
    top: -60px;
    transform: translateX(-50%) rotate(0);
    bottom: auto;
    min-width: 230px;
  }
  .facebook-slider{
    padding-top: 100px;
  }
  .facebook-slider .swiper-wrapper{
    margin: 0 30px;
  }
  .facebook__container{
    padding: 0 40px;
  }
  .facebook-slider-item__img{
    height: 150px;
  }
  .facebook-slider-item{
    margin: 0 40px;
  }
  .facebook-slider .slick-next{
    right: -40px;
  }
  .facebook-slider .slick-prev{
    left: -45px;
  }
  .anniversaries__pagination{
    top: 310px;
    left: 20px;
  }
  .anniversaries__text{
    left: 20px;
    bottom: 50px;
  }
  //.supporters__items li{
  //  margin: 0;
  //}
  .supporters__container{
    padding: 30px 0 40px;
  }
  .container_whole{
    margin-top: 97px;
  }
  .anniversaries__slider,
  .anniversaries .slick-track{
    height: 350px;
  }
  .carouselSlide .slick-track,
  .carouselSlide{
    height: 500px;
  }
  .carousel-dots{
    bottom: 30px;
    left: 20px;
  }
}

@media (max-width: 479px) {
  .facebook-slider-item{
    margin: 0;
  }
  .supporters__items li{
    margin: 0 0;
  }
}
@media (max-width: 370px) {
  .carouselBtn a {
    font-size: 15px;
    line-height: 20px;
    height: auto;
  }
  .carouselBtn{
    display: flex;
    align-items: center;
  }
}