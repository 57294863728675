.banner{
  margin-bottom: 45px;
  position: relative;
}
.banner__wrapper{
  display: flex;
}
.banner__container{
  //max-width: 92%;
  max-width: 1670px;
  width: 100%;
  margin: auto;
  @media (max-width: 1750px) {
    padding-left: 40px;
    padding-right: 40px;

  }
}
.banner::before{
  content: '';
  background: linear-gradient(-150deg, #008591 2%, #201738 100% );
  width: 40%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  @media (max-width: 1199px) {
    width: 55%;
  }
}
.banner__info{
  width: 40%;
  padding: 60px 60px 50px 45px;
  min-height: 500px;
  display: flex!important;
  flex-direction: column!important;
  justify-content: center!important;
  flex-flow: column!important;
  //align-items: center;
  //height: 100%;
  //height: 390px;
  @media (min-width: 1850px) {
    width: 35%;
  }
  @media (max-width: 1199px) {
    padding-left: 0;
    width: 45%;
    padding-right: 30px;
  }
}
.banner__info h1{
  font-size: 32px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  position: relative;
  font-family: 'ff-tisa-sans-web-pro';
}
.banner__info p:first-of-type::before{
  content: '';
  position: absolute;
  top: 0;
  left: 3px;
  width: 18px;
  height: 4px;
  background-color: #ebebeb;
}
.banner__info p{
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  position: relative;
  font-family: 'Open Sans';
}
.banner__info p:first-of-type{
  margin-top: 20px;
  padding-top: 30px;
}
.banner .container{
  display: flex;
  max-width: 1670px;
  margin: auto;
}
.needBlock{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 39.5%;
  @media (max-width: 1199px) {
    left: 46%;
  }
}
.needBlock iframe{
  width: 100%;
  height: 100%;
}
.content__wrapper{
  margin: auto;
  margin-bottom: 45px !important;
  display: flex;
  justify-content: space-between;
}
.content__wrapper::before{
  display: none;
}
.content__info{
  width: 40%;
  padding: 15px 30px 15px 0;
  .content__info--wrapper {
    p {
      + p {
        margin-top: 15px;
      }
    }
  }
}
.content__form{
  width: 55%;
  background-color: #f4faff;
}
#bbox-root .BBFormSection{
  margin: auto;
}
.content__info h3{
  font-size: 24px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 25px;
  font-family: ff-tisa-sans-web-pro;
}

.content__info p{
  font-size: 16px;
  color: #1e5ba1;
  line-height: 30px;
  font-family: 'Open Sans';
}

.sticky {
  position: fixed;
  z-index: 101;
}
.stop {
  position: relative;
  z-index: 101;
}
[data-bbox-part-id].BBFormContainer .BBFormSectionHeading label{
   font-size: 24px;
   color: #1e5ba1;
   font-weight: 700;
   line-height: 40px;
   font-family: ff-tisa-sans-web-pro;
}
[data-bbox-part-id].BBFormContainer .BBFormFieldLabelGivingLevel,
[data-bbox-part-id].BBFormContainer .BBFormFieldLabel,
[data-bbox-part-id].BBFormContainer .BBFormSubmitbutton{
  font-size: 16px;
  color: #414141;
  font-weight: 700;
  line-height: 24px;
  font-family: ff-tisa-sans-web-pro;
}
[data-bbox-part-id].BBFormContainer .BBFormSubmitbutton{
  color: #ffffff;
  background-image: none!important;
  border-radius: 8px;
  background-color: #00B200;
}
.BBFormContainer[data-bbox-part-id] *{
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}
.BBFormContainer[data-bbox-part-id] .BBLinkSecureInfo{
  color: #64afed;
  text-decoration: none;
  transition: .3s;
  border: none!important;
}
.BBFormContainer[data-bbox-part-id] .BBLinkSecureInfo:hover{
  color: #1e5ba1;
}
.BBFormContainer[data-bbox-part-id] .BBFormFieldContainerGivingLevels span:first-child{
  font-weight: bold;
}

@media (min-width: 2400px) {
  .banner::before{
    width: 44%;
  }
  .banner{
    min-height: 600px;
  }
}

@media (max-width: 991px) {
  &.page-template-page-donate-drum {
    .content__wrapper {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .content__wrapper{
    display: block;
    max-width: none;
    padding: 0;
  }
  .content__info,
  .content__form{
    width: 100%;
    padding: 0;
  }
  .banner::before,
  .banner__info{
    width: 100%;
  }
  .banner__info{
    //padding: 50px 0;
    min-height: 80px;
    padding-right: 0;
    padding-bottom: 0;
    height: auto!important;
  }
  .needBlock{
    width: 100%;
    position: relative;
    z-index: 100;
    height: 400px;
    left: 0;
    background-position: center!important;
    margin-top: 50px;
  }
  .banner__container{
    max-width: none;
    //padding: 0 20px 20px;
    padding: 0 60px 60px;
  }
  .content__info--wrapper{
    margin-bottom: 45px;
  }
}

@media (max-width: 600px) {
  .needBlock{
    height: 250px;
    margin-top: 20px;
  }
  .banner__container{
    padding: 0 20px 40px;
  }
  .banner__info h1{
    font-size: 22px;
    line-height: 28px;
  }
  .banner__info{
    padding-top: 30px;
  }
  .banner{
    margin-bottom: 40px;
  }
}

