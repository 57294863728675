@mixin rwd($px) {
  $max-width: $px#{px};
  @media screen and (max-width: $max-width) {
    @content
  }
}

@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}

@mixin flexbox {
  display: flex;
}

@mixin inline-flex {
  display: inline-flex;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
  flex-flow: $values;
}

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  order: $int;
}

@mixin flex-grow($int: 0) {
  flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
  flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
  flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  $fg-boxflex: $fg;
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }
  flex: $fg $fs $fb;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

@mixin flex-grid($columnNumber, $spacingPercent, $rebuild: null) {
  $contentPercent: 100%;
  $sideMargin: ($spacingPercent/2);
  $marginInt: strip-units($sideMargin);
  @if $rebuild == null {
    @include justify-content(flex-start);
    @include flex-flow(wrap);
    transition: all .35s ease;
    @include flexbox();
  }
  margin: #{0} -#{$sideMargin};
  width: $contentPercent + $spacingPercent;
  > * {
    width: ($contentPercent/$columnNumber) - $spacingPercent;
    margin: 0 #{$sideMargin} ($marginInt + ($marginInt / $columnNumber) * 1em)
  }
}

* {
  outline: none !important
}

.echo_bg {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
}

.btn-dark-blue {
  color: #17579f;
  border-color: #17579f;
  &:hover {
    background-color: #073f8c;
    color: #fff;
  }
}

&.page-template-page-get-involved,
&.page-template-page-get-involved-donate,
&.page-template-page-get-involved-self-advocacy,
&.page-template-page-get-involved-plannedgiving,
&.page-template-page-get-involved-volunteer,
&.page-template-page-get-involved-familyadvocacy {
  .blue_bg.list_cols ul {
    column-count: 2;
    -moz-column-count: 2;
    @include rwd(640) {
      column-count: 1;
      -moz-column-count: 1;
    }
  }
}


&.page-template-page-photo-gallery {
  img {
    height: auto;
    max-width: 100%;
  }
  .gallery {
    color: #fff;
    background: #17579f;
    padding: 40px 0 80px;
    border-top: 10px solid #f4a636;
    border-bottom: 10px solid #f4a636;
  }
  .gal_titles {
    @include flexbox;
    li {
      @include flex(1);
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      padding: 11px 1% 12px;
      transition: all 0.3s ease;
      border: 2px solid #fff;
      &:not(:first-child) {
        border-left: none
      }
      &:hover, &.active {
        color: #17579f;
        background: #fff;
      }
    }
    @include rwd(767) {
      @include flex-direction(column);
      li {
        font-size: 16px;
        padding: 7px 1% 8px;
        border: 2px solid #fff !important;
        &:not(:first-child) {
          border-top: none !important
        }
      }
    }
  }
  .tab_content {
    opacity: 0;
    height: 0;
    &.active {
      opacity: 1;
      height: 100%;
    }
    .info {
      width: 640px;
      max-width: 94%;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0 25px;
      h3 {
        color: #fff
      }
    }
  }
  .photo_gallery {
    margin-bottom: 10px;
    .swiper-slide {
      height: 500px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      @include rwd(1024) {
        height: 400px
      }
      @include rwd(767) {
        height: 340px
      }
      @include rwd(480) {
        height: 270px
      }
    }
  }
  .gallery_thumbs {
    @include rwd(639) {
      display: none
    }
    .swiper-wrapper {
      margin-left: -47.2%;
      @include rwd(1366) {
        margin-left: -47%
      }
      @include rwd(1280) {
        margin-left: -46.7%
      }
      @include rwd(1152) {
        margin-left: -46.2%
      }
      @include rwd(1024) {
        margin-left: -45.8%
      }
      @include rwd(930) {
        margin-left: -45.4%
      }
      @include rwd(840) {
        margin-left: -45%
      }
      @include rwd(768) {
        margin-left: -44.5%
      }
      @include rwd(680) {
        margin-left: -43.7%
      }
    }
    .swiper-slide {
      width: 80px;
      transition: all 0.3s ease;
      &.swiper-slide-active {
        opacity: 0.5
      }
    }
  }
  .custom_prev, .custom_next {
    font-size: 80px;
    line-height: 1;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    &.swiper-button-disabled {
      opacity: 0.4;
      cursor: default;
    }
    @include rwd(640) {
      font-size: 54px;
      margin-top: -27px;
    }
  }
  .custom_prev {
    left: 3.5%
  }
  .custom_next {
    right: 3.5%
  }
}

&.page-template-page-career-landing-page {
  .career--lp1 {
    padding-top: 0
  }
  .career--lp1-bkgd {
    padding: 0;
    background: none;
  }
  .slide_img {
    height: 100%
  }
}

.nav-tabs > li {
  &.active, &.current-menu-item, &.menu-item.current-menu-item {
    > a, > a:hover {
      border-color: transparent;
      color: #17579f !important;
    }
  }
  &.menu-item > a {
    border-radius: 0;
    font-weight: 400;
    font-family: "Gotham";
    color: #fff !important;
    &:hover {
      color: #17579f !important
    }
  }
}

.panel, .panel > .panel-collapse {
  > .list-group .list-group-item {
    font-weight: 400;
    border-radius: 0 !important;
  }
}

.evemtmobile--menu .current-menu-item .list-group-item {
  font-weight: 700;
}

.echo_darkblue {
  color: #17579f
}

.echo_img {
  height: auto;
  display: block;
  max-width: 100%;
}

.echo_darkblue_inside {
  h1, h2, h3, h4, h5, h6, li, p {
    span {
      color: #17579f;
      text-decoration: none !important;
    }
  }
}

.echo_our_stories:nth-child(even) {
  background: #f4faff;
  .container {
    @include flexbox;
    @include flex-flow(row-reverse);
    @include rwd(991) {
      display: block
    }
  }
}

#quote-carousel .item {
  @media screen and (min-width: 1200px) {
    -webkit-background-size: cover;
    background-size: cover;
  }
}

.icenter {
  right: 40%;
  @include rwd(767) {
    top: 75% !important;
    right: 30%;
  }
  @include rwd(480) {
    right: 15%;
  }
  
}

#anniversaries-carousel {
  h2 {
    color: #fff
  }
}

.echo_footer_darkblue {
  em, em a {
    color: #17579f;
    font-style: normal;
  }
}

.sf_learn_more {
  ul {
    column-count: 2;
    -moz-column-count: 2;
    padding: 20px 2.8% 0;
    li {
      margin: 0 10.8%;
      a {
        font-family: "Open Sans";
        color: #ffffff;
        &:hover {
          color: #f4a636;
        }
      }
    }
    @include rwd(767) {
      column-count: 1;
      -moz-column-count: 1;
      li {
        margin: 0
      }
    }
  }
}

.services_cols_slider {
  .custom_prev, .custom_next {
    font-size: 60px;
    line-height: 1;
    color: rgba(#ccc, 0.7);
    position: absolute;
    top: 50%;
    margin-top: -37px;
    cursor: pointer;
    transition: all 0.35s ease;
    z-index: 1;
    &:hover {
      color: #fff
    }
    &.swiper-button-disabled {
      color: rgba(#ccc, 0.4);
      cursor: default;
      &:hover {
        color: rgba(#ccc, 0.4)
      }
    }
    @include rwd(568) {
      display: none
    }
  }
  .custom_prev {
    left: 3.5%;
    transform: rotate(45deg);
  }
  .custom_next {
    right: 3.5%;
    transform: rotate(-135deg);
  }
  .swiper-pagination {
    bottom: 35px !important;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      opacity: 1;
      margin: 0 3px;
      border: 1px solid #fff;
      background: transparent;
      transition: all 0.35s ease;
      &:hover, &.swiper-pagination-bullet-active {
        background: #fff;
        opacity: 1;
      }
    }
  }
}

.echo_com_fam_ss {
  margin-bottom: 80px;
  h5 {
    padding-bottom: 15px
  }
  .img-responsive {
    margin-bottom: 20px !important
  }
}

&.page-template-page-community-family-support-services {
  .services_wrap_container .services-small-banner {
    max-width: 670px;
    h1 {
      font-size: 27px
    }
  }
  .service-content2-right {
    padding: 40px 5.4% 50px;
    margin: 40px 0 40px 16.66667%;
    h4 {
      padding-top: 0
    }
    li {
      font-family: 'Open Sans';
      line-height: 1.875;
      color: #fff;
    }
    @include rwd(991) {
      margin: 40px 0
    }
  }
}

.blue_info_before_meet {
  padding-bottom: 40px;
  @media screen and (min-width:992px) { 
    padding-bottom: 0;
  }
  h5, p {
    color: #414141
  }
}

.rows_with_slider {
  .swiper-pagination {
    bottom: 15px !important
  }
  &:nth-child(even) {
    background: #f4faff;
    .flex_reverse {
      width: 100%;
      @include flexbox;
      margin-left: -6%;
      @include flex-flow(row-reverse);
      @include rwd(991) {
        display: block;
        margin-left: 0;
      }
    }
  }
}

&.page-template-page-pathways {
  .list_cols ul {
    column-count: 2;
    -moz-column-count: 2;
    @include rwd(568) {
      column-count: 1;
      -moz-column-count: 1;
    }
  }
}

.echo_aff_col {
  width: 25% !important;
  @include rwd(960) {
    width: 50% !important
  }
  @include rwd(520) {
    width: 100% !important
  }
}

.echo_house_opp:nth-child(even) .flex_reverse {
  width: 100%;
  @include flexbox;
  margin-left: -6%;
  @include flex-flow(row-reverse);
  @include rwd(991) {
    display: block;
    margin-left: 0;
  }
}

.show_on_mobile {
  display: none !important;
  opacity: 0;
  @include rwd(991) {
    display: block !important
  }
}

.cl_sw_slide {
  @extend .echo_bg
}

.echo_clp_content {
  color: #fff;
  p em {
    font: italic 400 #{20px}/1.8 ff-tisa-sans-web-pro, sans-serif;
    color: #f4a636;
  }
}

.echo_apply {
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: #0f6473
    }
  }
}



&.page-template-page-contact-us, .page-template-page-thank-you {
  /*padding-top: 195px;
  @media screen and (max-width: 1310px) and (min-width: 1200px) {
    padding-top: 246px
  }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    padding-top: 202px
  }
  @include rwd(992) {
    padding-top: 152px
  }
  @include rwd(767) {
    padding-top: 141px
  }*/
  
  h3 {
    color: #17579f
  }
  h6 {
    color: #111
  }
  em {
    font-style: italic
  }
  p {
    color: #414141;
    word-wrap: break-word;
  }
  .contact_info {
    background: #e3f0fc;
  }
  .flex {
    @include flexbox;
    @include justify-content(space-between);
    align-items: stretch;
    * {
      min-width: 1px
    }
    > div {
      flex-basis: 50%
    }
    @include rwd (991) {
      display: block
    }
    .left{

    }
    .right{
      position: relative;
    }
    #contact_map{
    
      @media screen and (min-width:992px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  
  /*.involved-banner, .contact_info, .add_info {
    .container {
      max-width: 94% !important;
      width: 1440px !important;
      padding: 0 !important;
    }
  }*/
  
  .involved-banner {
    padding: 60px 0;
    background: #17579f;
    @include rwd(991) {
      padding: 35px 0;
      .involved-small-banner {
        padding: 20px 2.7%
      }
      h1 {
        font-size: 24px
      }
    }
  }
  .involved-small-banner {
    width: 640px;
    max-width: 100%;
    padding: 40px 2.7%;
    background: #4097ee;
    box-shadow: 0 39px 60px rgba(#000, .24);
  }
  
  .left {
    padding: 60px 3% 60px 16%;
    @include rwd(1366) {
      padding: 60px 3% 60px 6%
    }
    @include rwd(991) {
      padding: 50px 0;
      text-align: center;
    }
    @include rwd(480) {
      padding: 30px 0
    }
    p a {
      color: #17579f;
      transition: all 0.3s ease;
      &:hover {
        color: #f4a636
      }
    }
  }
  
  .map_bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    img {
      z-index: -1;
      position: relative;
    }
  }
  
  #contact_map {    
    @include rwd(991) {
      height: 450px !important
    }
    @include rwd(767) {
      height: 380px !important
    }
  }
  
  .add_info {
    .left {
      background: #e3f0fc
    }
    .right {
      color: #fff;
      background: #17579f;
      padding: 70px 3% 90px;
      @include rwd(480) {
        padding: 30px 3%
      }
      h6 {
        color: #fff
      }
    }
  }
  
  //cf7
  div.wpcf7 {
    width: 640px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    .inputs_wrap {
      @include flexbox;
      @include justify-content(space-between);
      @include rwd(640) {
        display: block
      }
      > * {
        @include flex-basis(46.88%)
      }
      > div {
        @include flexbox;
        @include justify-content(space-between);
        @include rwd(480) {
          display: block
        }
        > * {
          @include flex-basis(43.33%)
        }
      }
    }
    .wpcf7-form-control-wrap {
      display: block !important;
      position: relative;
    }
    .wpcf7-not-valid-tip {
      background: #fff;
      border: 1px solid #ff5f5f;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      font: 400 16px/38px 'Gotham';
      color: #111;
      height: 40px;
      padding: 0 8px;
      border-radius: 0;
    }
    .wpcf7-response-output {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      font: 400 16px/1.333 'Gotham';
      color: #fff;
      border: none;
      border-radius: 0;
      padding: 7px 10px;
      text-align: center;
      &.wpcf7-mail-sent-ng {
        background: #1091ae;
        position: relative
      }
      &.wpcf7-mail-sent-ok {
        background: #16a085;
        position: relative
      }
      &.wpcf7-validation-errors, &.wpcf7-spam-blocked {
        background: #ff5f5f;
        position: relative
      }
    }
    label {
      display: block;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]), textarea {
      display: block;
      width: 100%;
      height: 40px;
      border: none;
      font: 400 16px 'Gotham';
      color: #111;
      margin-top: 5px;
      padding: 0 10px;
      border-radius: 0;
      background: #cce6ff;
    }
    textarea {
      resize: none;
      overflow: auto;
      height: 160px;
      padding: 10px;
      @include rwd(480) {
        height: 100px
      }
    }
    .for_submit {
      @include flexbox;
      @include align-items(flex-start);
      @include justify-content(space-between);
      padding-top: 10px;
      @include rwd(480) {
        display: block;
        text-align: center;
      }
    }
    button {
      color: #111;
      border: none;
      cursor: pointer;
      background: #cce6ff;
      transition: all 0.3s ease;
      &:hover {
        background: #a9f8bd
      }
    }
    .screen-reader-response, .ajax-loader {
      display: none
    }
    .selbel_w {
      width: 100%;
      height: 40px;
      display: block;
      margin-top: 5px;
      position: relative;
      font: 400 16px/39px 'Gotham';
      select {
        color: #111;
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
        position: relative;
        background: #cce6ff;
        opacity: 0;
        z-index: 1;
        -webkit-appearance: menulist-button;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        + span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          color: #111;
          border: none;
          border-radius: 0;
          background: #cce6ff;
          padding: 0 25px 0 10px;
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #111 transparent transparent transparent;
            position: absolute;
            right: 15px;
            top: 17px;
          }
          &.err {
            border-color: #ef4036
          }
        }
        option {
          padding: 10px
        }
      }
    }
    .wpcf7-recaptcha {
      > div {
        @include rwd(480) {
          margin: 0 auto 20px
        }
      }
      & ~ .wpcf7-not-valid-tip {
        height: 78px;
        padding: 11px;
        font-size: 15px;
        line-height: 1.3;
      }
    }
  }
}

.dropdown-menu > li > a {
  @include rwd(767) {
    white-space: normal;
  }
}



.anniversaries__our{ 
  color: #4097ee;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  @media screen and (min-width:768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  p{
    padding-bottom: 60px;
  }
}
.anniversaries-slider{
  padding-bottom: 40px;
  @media screen and (min-width:768px) {
    padding-bottom: 0;
  }
}
.anniversaries-slider__img{
  background-size: cover;
  background-position: center;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  &:after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.2);
    z-index: 1;
    content: "";
  }
}
.anniversaries-slider__item{
  position: relative;
}
.anniversaries-slider__text{  
  z-index: 2;
  left: 15px;
  bottom: 15px;
  position: absolute;  
  @media screen and (min-width:768px) {
    left: 60px;
    bottom: 40px;
  }
  h2{
    color: #fff;
    font-family: ff-tisa-sans-web-pro, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    text-align: left !important;
    margin-bottom: 0;
  }
  p{    
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left !important;
  }
  @media screen and (min-width:768px) {
    h2{
      font-size: 25px;
    }
    p{
      font-size: 18px;
    }
  }
}


.grecaptcha-badge{
  z-index:1000; 
}