.historyPage .banner::before{
  background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
}
.our-stories-wrap{
  max-width: 1000px;
  margin: auto;
}
.our-stories__inner{
  position: relative;
}
.our-stories__list{
  overflow: hidden;
}
.our-stories__item{
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  @media screen and (min-width:768px) {
    opacity: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    visibility: hidden;
    transition: 1s;
    &.animated{
      opacity: 1;
      visibility: visible;
      + .animated {
        animation-delay: 1.5s!important;
      }
      &:before{
        opacity: 1;
      }
      &:after{
        height: calc(100% - 15px);
      }
    }
    &:before{
      width: 33px;
      height: 33px;
      //opacity: 0;
      background-color: #1e5ba1;
      content: "";
      position: absolute;
      left: 50%;
      top: 60px;
      border-radius: 100%;
      transform: translate(-50%, 6px);
      z-index: 10;
    }
    &:after{
      width: 7px;
      height: 0;
      background-color: #1e5ba1;
      content: "";
      position: absolute;
      left: 50%;
      top: 60px;
      transform: translate(-50%, 21px);
      z-index: 10;
      transition: 1s;
      //transition-delay: 1.5s;
    }
    &:last-child:after{
      display: none;
    }
    &:nth-child(even){
      .our-stories__image{
        order: 2;
      }
      .our-stories__body{
        order: 1;
        text-align: right;
      }
    }
  }
}
.our-stories__inner{
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (min-width:768px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.our-stories__col{
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width:600px) {
    padding-left: 46px;
    padding-right: 46px;
  }
}
.our-stories__image{
  padding-bottom: 40px;
  @media screen and (min-width:768px) {
    width: 50%;
    padding-bottom: 0;
  }
}
.our-stories__img{
  position: relative;
  height: 0;
  padding-bottom: 50%;
  background: center / cover;
}
.our-stories__body{
  @media screen and (min-width:768px) {
    width: 50%;
  }
}
.our-stories__year{
  font-family: ff-tisa-sans-web-pro;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: #1e5ba1;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  &.main-title {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 20px;
    &:after {
      display: none;
    }
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

}
.our-stories__year::after{
  content: '';
  width: 100%;
  height: 2px;
  background-color: #ebebeb;
  position: absolute;
  left: 0;
  bottom: 0;
}
.our-stories__text{
  p{
    font-family: 'Open Sans';
    font-size: 16px;
    color: #414141;
    line-height: 30px;
  }
}
.section-banner{
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  background: #B5ADD6;
  color: #fff;
  @media screen and (min-width:768px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.section-banner__inner{
  width: 100%;
  padding: 25px 20px;
  max-width: 650px;
  background: #8071B4;
  margin: 0 auto;
  box-shadow: 0 35px 80px #8071B4;
  @media screen and (min-width:768px) {
    padding: 25px 40px 35px;
  }
}
.section-banner__title{
  font-size: 30px;
  line-height: normal;
  color: inherit;
  margin: 0;
  @media screen and (min-width:768px) {
    font-size: 40px;
  }
}
.section-banner__sub-title{
  font-size: 20px;
  line-height: normal;
  color: inherit;
  margin: 0;
  padding-top: 15px;
  @media screen and (min-width:768px) {
    font-size: 30px;
    padding-top: 0;
  }
}
.our-stories-yearsoffree{
  padding-top: 55px;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  color: #8071b3;
  font-weight: bold;
  background: #CFE5FA;
  padding-bottom: 20px;
  @media screen and (min-width:768px) {
    padding-bottom: 0;
  }
}
.similar-articles {
  .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  img{
    width: 100%;
  }
}
.similar-articles__inner{
  border-radius: 2px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  .home-blog-info{
    flex: 1;
  }
}
.similar-articles__images{
  position: relative;
}
.similar-articles__img{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 60%;
  background: center / cover;
}
.similar-articles__date{
  color: #17579f;
  font-size: 16px;
  margin-bottom: 15px;
}
.similar-articles__readmore {
  font-size: 16px;
  font-weight: 700;
  font-family: ff-tisa-sans-web-pro,sans-serif !important;
  position: absolute;
  bottom: 30px;
}
.similar-articles__title{
  padding-bottom: 40px;
  text-align: center;
  h2{
    font-size: 29px;
    color: #404040 !important;
  }
}
.our-stories-donate{
  background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
  padding: 110px 0;
  text-align: center;
}
.our-stories-donate__inner{
  width: 750px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.our-stories-donate__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 28px;
  margin-bottom: 34px;
  position: relative;
}
.our-stories-donate__title::after{
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  position: absolute;
  bottom: 0;
  left: 0;
}
.our-stories-donate__text{
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  p{
    font-family: 'Open Sans';
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
  }
}
.our-stories-donate__box{
  background: #17579f;
  padding: 30px 40px 40px;
}
.our-stories-donate__box-text{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    br{
      display: none;
    }
  }
}
.historyBtn{
  margin: 0 auto;
}

@media (max-width: 767px) {
  .our-stories__list {
    padding-bottom: 90px;
  }
}