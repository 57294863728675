&.page-template-page-arts {
  font-family: 'Open Sans', sans-serif;

  .grecaptcha-badge {
    bottom: 60px!important;
  }

  .banner {
    margin-bottom: 0;
  }
  .row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    min-width: 100%;
  }
}

.btnWrap.banner-btn-wrap {
  margin-top: 24px;
  position: relative;
  height: 60px;
  background-color: #f08400;
  background-size: cover;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  max-width: 285px;
  a {
    line-height: 60px;
    padding-right: 50px;
    padding-left: 20px;
    &:before,
    &:after {
      right: 19px;
    }
  }
}

.programs-about {
  padding-top: 80px;
  padding-bottom: 80px;
  .text {
    max-width: 872px;
    font-size: 16px;
    color: #414141;
    font-weight: 600;
    line-height: 30px;
    margin: 0 auto 80px;
  }
  .logo-item {
    padding: 0 15px;
    width: 25%;
    a {
      padding: 24px 30px 34px;
      display: block;
      transition: all .25s;
      cursor: pointer;
      img {
        max-width: 100%;
      }
      &:hover {
        box-shadow: 0px 4px 20px rgba(0,0,0,0.2);
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .logo-item {
      a {
        padding: 20px 15px 25px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .logo-item {
      a {
        padding: 10px 5px 15px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .logo-item {
      width: 50%;
      margin-bottom: 10px;
    }
    .text {
      margin-bottom: 40px;
    }
  }
}


.art-group {
  -webkit-background-size: cover;
  background-size: cover;
  padding: 80px 0;
  position: relative;
  &:after {
    opacity: .6;
    background-color: #000;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: "";
    z-index: 1;
  }
  .containerInner {
    position: relative;
    z-index: 2;
    .row {
      justify-content: space-between;
      &.first-row {
        margin-bottom: 80px;
        align-items: flex-start;
      }
    }
  }
  .main-img {
    background-size: cover;
    background-position: center;
    position: relative;
    width: calc(50% - 45px);
    text-align: center;
    padding: 60px 70px 80px;
    margin-left: 15px;
    &:after {
      opacity: .85;
      background-color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: "";
      z-index: 1;
    }
    img {
      position: relative;
      z-index: 2;
      max-width: 100%;
      max-height: 360px;
    }
  }
  .content {
    width: calc(50% - 45px);
    h2 {
      font-family: 'ff-tisa-sans-web-pro', sans-serif;
      font-size: 26px;
      color: #f5522e;
      font-weight: 700;
      line-height: 34px;
      border-bottom: 2px solid #ebebeb;
      margin-bottom: 24px;
      padding-bottom: 20px;
    }
    .text {
      font-size: 16px;
      color: #ffffff;
      line-height: 30px;
      margin-bottom: 45px;
      &.under-btn-text {
        font-weight: 700;
        a {
          transition: all .25s;
          &:hover {
            color: orange;
          }
        }
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
    .btnWrap {
      display: flex;
      flex-wrap: wrap;
      background: transparent;
      height: auto;
      + .text {
        margin-top: 30px;
        margin-bottom: 0;
      }
      a {
        width: auto;
        background: #1e5ba1;
        line-height: 60px;
        padding-right: 50px;
        padding-left: 20px;
        font-size: 16px;
        transition: all .25s;
        &:hover {
          box-shadow: 0px 4px 12px rgba(0,0,0,0.5);
          background-color: darken(#1e5ba1,10);

        }
        &:before,
        &:after {
          right: 19px;
        }
        &:before {
          width: 12px;
        }
        &:after {
          width: 10px;
          height: 10px;
        }
        height: 60px;
      }
      .color {
        background: #f5522e;
        margin-right: 24px;
        transition: all .25s;
        &:hover {
          background-color: darken(#f5522e,10);
        }
      }
      &:hover {
        box-shadow: none;
      }
    }
    iframe {
      max-width: 100%;
      height: 415px;
      width: 100%;
    }
  }
  .gallery-image {
    width: calc(50% - 45px);
    margin-left: 15px;
    .row {
      margin-left: -12px;
      margin-right: -12px;
      justify-content: flex-start;
    }
    a {
      background-color: #ebebeb;
      height: 123px;
      padding: 5px;
      width: calc(33% - 24px);
      margin: 0 12px 24px;
      cursor: pointer;
      text-align: center;
      background-position: center;
      -webkit-background-size: cover;
      background-size: cover;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &.group-2 {
    .content {
      h2 {
        color: #59bd8e;
      }
      .btnWrap {
        .color {
          background-color: #59bd8e;
          &:hover {
            background-color: darken(#59bd8e,10);
          }
        }
      }
    }
  }
  &.group-3 {
    .content {
      h2 {
        color: #5eb1ce;
      }
      .btnWrap {
        .color {
          background-color: #5eb1ce;
          &:hover {
            background-color: darken(#5eb1ce,10);
          }
        }
      }
    }
  }
  &.group-4 {
    .content {
      h2 {
        color: #ff391f;
      }
      .btnWrap {
        .color {
          background-color: #ff391f;
          &:hover {
            background-color: darken(#ff391f,10);
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .main-img,
    .gallery-image{
      width: 100%;
      margin-bottom: 40px;
      margin-left: 0;
    }
    .content {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 50px 0;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .main-img {
      padding: 40px 70px 50px;
    }
    .gallery-image {
      a {
        width: calc(33% - 10px);
        margin: 0 5px 10px;
      }
    }
    .content {
      .text {
        font-size: 14px;
        line-height: 24px;
      }
      .btnWrap {
        .color {
          margin-right: 10px;
        }
        a {
          padding-right: 40px;
          padding-left: 10px;
        }
      }
    }
  }
}

.book-us {
  position: relative;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  padding: 120px 0;
  &:before {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: '';
    background-color: rgba(255,255,255,.8);
  }
  .containerInner {
    position: relative;
    z-index: 2;
  }
  .content {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    padding: 100px;
    &.book-us-content {
      padding: 36px 100px;
    }
    .row {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .contact__form {
    width: 100%;
    .col {
      max-width: 50%;
      padding-right: 40px;
      width: 100%;
      + .col {
        padding-right: 0;
        padding-left: 40px;
      }
      &.first-col {
        label {
          margin-bottom: 25px;
        }
      }
    }
    ::-webkit-input-placeholder {
      font-size: 16px;
    }
    ::-moz-placeholder {
      font-size: 16px;
    }
    :-ms-input-placeholder {
      font-size: 16px;
    }
    :-moz-placeholder {
      font-size: 16px;
    }
    select {
      font-size: 16px;
    }
    input {
      line-height: 1;
    }
    textarea {
      resize: none;
    }
    .form-blue{
      color:#1e5ba1;
      font-size:16px;
      line-height:26px;
      font-weight:700; 
    }
    .Equipment{
      .wpcf7-list-item{
        width:100%; 
        input{
          float:left;
          //width:auto;
          height: 14px;
          width: 14px;
          margin-top: 14px;
          border: 0;
          border-radius: 14px;
          margin-left: 0;
        }
        .wpcf7-list-item-label{
          font-size:18px;
          line-height:32px;
          font-weight:700;
          color: #414141;
          position:relative;
          top:5px;
          left:10px;
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .e-length input:focus::-webkit-input-placeholder,
  .e-length textarea:focus::-webkit-input-placeholder{
    transform: translateX(-250px);
  }
  .e-length input:focus::-moz-placeholder,
  .e-length textarea:focus::-moz-placeholder{
    transform: translateX(-250px);
  }
  .e-length input:focus:-ms-input-placeholder,
  .e-length textarea:focus:-ms-input-placeholder{
    transform: translateX(-250px);
  }
  .e-length input:focus:-moz-placeholder,
  .e-length textarea:focus:-moz-placeholder{
    transform: translateX(-250px);
  }
  input[type="checkbox"] {
    height: 15px;
    width: 15px;
    background: transparent;
    border-radius: 2px;
    border: 1px solid #ccc;
    box-shadow: none;
    margin-right: 10px;
  }
  span.wpcf7-list-item {
    margin-left: 0;
  }
  .wpcf7-checkbox {
    span {
      display: inline-block;
      &.wpcf7-list-item {
        display: block;
        margin-left: 0;
        font-family: 'ff-tisa-sans-web-pro', sans-serif;
        font-size: 18px;
        color: #414141;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 5px;
        label {
          margin-bottom: 0;
          cursor: pointer;
        }
        &.last {
          margin-bottom: 25px;
        }
      }
    }
  }


  .blue-box {
    box-shadow: 0px 4px 20px rgba(0,0,0,0.2);
    background-color: #1e5ba1;
    padding: 36px 100px 24px;
    .h2 {
      color: #eb9800;
      padding-bottom: 24px;
      border-bottom: 2px solid #fff;
    }
    .desc {
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 12px;
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      li {
        margin-bottom: 12px;
        list-style: initial;
      }
      p {
        margin-bottom: 12px;
      }
    }
  }

  .h2 {
    font-family: 'ff-tisa-sans-web-pro', sans-serif;
    font-size: 26px;
    color: #1e5ba1;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    margin-bottom: 25px;
  }
  .desc {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    color: #414141;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    margin-bottom: 24px;
  }
  .note {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    color: #414141;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin-bottom: 48px;
    padding: 0 15px;
  }
  .form-subttl {
    font-size: 16px;
    color: #1e5ba1;
    font-weight: 700;
    text-decoration: none solid rgb(30, 91, 161);
    line-height: 30px;
    margin-bottom: 13px;
  }
  .for_submit {
    width: 100%;
    flex: 0 0 100%;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding-top:30px;
    p {
      font-size: 16px;
      color: #414141;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      margin-bottom: 30px;
    }
    button {
      width: 244px;
      text-align: left;
      padding-left: 30px;
    }
  }
  &.form-2 {
    padding: 0;
    .content {
      box-shadow: none;
    }
    .for_submit {
      display: block;
    }
  }
  @media screen and (max-width: 1239px) {
    .contact__form {
      .col {
        padding-right: 20px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .content {
      padding: 60px 55px;
      &.book-us-content {
        padding: 36px 55px;
      }
    }
    .blue-box {
      padding: 36px 55px 24px;;
    }
    .wpcf7-checkbox {
      span {
        &.wpcf7-list-item {
          font-size: 14px;
          label {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }
        }
      }
    }
    .contact__form {
      .col {
        padding-right: 15px;
        + .col {
          padding-left: 20px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 16px;
      }
      ::-moz-placeholder {
        font-size: 16px;
      }
      :-ms-input-placeholder {
        font-size: 16px;
      }
      :-moz-placeholder {
        font-size: 16px;
      }
      select {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding: 100px 0;
    .content {
      padding: 60px 40px;
      &.book-us-content {
        padding: 36px 40px;
      }
    }
    .blue-box {
      padding: 36px 40px 24px;;
    }
    .contact__form {
      .col {
        padding-right: 15px;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        + .col {
          padding-left: 15px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 14px;
      }
      ::-moz-placeholder {
        font-size: 14px;
      }
      :-ms-input-placeholder {
        font-size: 14px;
      }
      :-moz-placeholder {
        font-size: 14px;
      }
      input {
        line-height: 37px;
      }
      select {
        font-size: 14px;
        background-size: 20px;
        padding-right: 20px;
        line-height: 37px;
      }
    }
    &.form-2 {
      .content {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 60px 0;
    .content {
      padding: 40px 15px;
      &.book-us-content {
        padding: 24px 15px;
      }
    }
    .blue-box {
      padding: 26px 30px 20px;
    }
    .row {
      margin: 0;
      .col {
        flex-basis: 100%;
      }
    }
    .wpcf7-checkbox span.wpcf7-list-item {
      font-size: 14px;
    }
    .contact__form {
      .col {
        max-width: 100%;
        width: 100%;
        padding-right: 0;
        &.first-col {
          margin-bottom: 20px;
        }
        + .col {
          padding-left: 0;
        }
      }
    }
    &.form-2 {
      padding: 0;
      .content {
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        .col {
          margin-bottom: 30px;
        }
      }
    }
    .for_submit {
      padding-top: 25px;
    }
  }
  @media screen and (max-width: 349px) {
    .contact__form {
      .inputs_wrap {
        &.two {
          flex-direction: column;
          label {
            width: 100%;
          }
        }
      }
    }
  }
}


.volunteer {
  padding-top: 120px;
  h2 {
    font-family: 'ff-tisa-sans-web-pro', sans-serif;
    font-size: 26px;
    color: #1e5ba1;
    font-weight: 700;
    line-height: 34px;
    margin-bottom: 25px;
  }
  .text {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    color: #414141;
    font-weight: 600;
    text-decoration: none solid rgb(65, 65, 65);
    line-height: 30px;
  }
  .row {
    justify-content: space-between;
  }
  .vol-items {
    margin: 0;
  }
  .vol-item {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
  .col-text {
    max-width: 50%;
    padding-right: 45px;
    padding-left: 15px;
    width: 100%;
  }
  .col-img {
    max-width: calc(50% - 60px);
    width: 100%;
    margin-right: 15px;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  .btnWrap {
    width: 275px;
    height: 60px;
    background-color: #1e5ba1;
    background-size: cover;
    color: #ffffff;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
    a {
      line-height: 60px;
      font-size: 16px;
      padding: 0px 24px 0px 25px;
      &:before {
        transform: rotate(90deg);
        width: 12px;
        right: 36px;
        top: 47%;
      }
      &:after {
        transform: rotate(135deg);
        height: 10px;
        width: 10px;
        right: 37px;
        top: 44%;
      }
    }
  }
  .vol-item {
    max-width: calc(33.3% - 53px);
    margin-top: 80px;
    .img {
      height: 203px;
      background-position: center center;
      background-size: cover;
      margin-bottom: 24px;
    }
    .ttl {
      font-family: 'ff-tisa-sans-web-pro', sans-serif;
      font-size: 22px;
      color: #1e5ba1;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 12px;
    }
    .text {
      font-size: 14px;
      color: #414141;
      line-height: 24px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 80px;
    .col-text {
      max-width: 100%;
      padding-right: 0;
    }
    .col-img {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      height: 200px;
    }
    .vol-item {
      max-width: calc(33% - 23px);
      margin-top: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    .vol-item {
      max-width: calc(50% - 15px);
    }
    .col-img {
      display: none;
    }
    .col-text {
      padding-left: 0;
    }
    .vol-item {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .vol-item {
      max-width: 100%;
    }
  }
}

.arts-donate {
  font-family: 'ff-tisa-sans-web-pro', sans-serif;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  display: block;
  height: 60px;
  transition: all .25s;
  text-decoration: none!important;
  &:hover,
  &:visited {
    text-decoration: none!important;
  }
  &:hover {
    color: #eb9800;

  }

}
.drumMenuWrap.art {
  height: auto;
  position: relative;
  z-index: 2000;
}

.wpcf7-form-control.wpcf7-radio {
  label {
    margin-bottom: 0!important;
    cursor: pointer;
  }
}

.mfp-container,
button.mfp-close, button.mfp-arrow {
  cursor: pointer!important;
}