.getInvolved {
	.info .btnWrap {
		margin-top: 24px;
	}

	.section-header {
		font-family: "ff-tisa-sans-web-pro";
		font-size: 56px;
		text-align: center;
		font-weight: bold;
		color: #1e5ba1;
		margin: auto;
		border-bottom: 4px solid #009dad;
	}

	.snippet {
		text-align: center;
		margin: 24px auto 60px;
	}

	.info h3 {
		font-family: "ff-tisa-sans-web-pro";
		font-size: 26px;
		font-weight: bold;
		color: #1e5ba1;
		line-height: 34px;
	}

	.info hr {
		border: 1px solid #ebebeb;
		margin: 24px 0;
	}

	.containerInner p,
	.containerInner li {
		font-family: "Open Sans", sans-serif;
		font-size: 16px;
		color: #414141;
		line-height: 30px;
	}

	.info ul {
		list-style: disc;
		padding-left: 20px;
	}

	.row {
		display: flex;
		margin: 200px -50px;
		min-height: 400px;
	}

	.row:first-child {
		margin-top: 120px;
	}

	.row:nth-child(even) .gallery {
		order: 2;
	}

	.gallery {
		width: 50%;
		position: relative;
		padding: 0 50px;
	}

	.gallery img {
		position: absolute;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		width: calc(100% - 100px);
		height: 100%;
		object-fit: cover;
	}

	.gallery img.active {
		opacity: 1;
	}

	.gallery .slider-btns {
		display: flex;
		position: absolute;
		right: 50px;
		bottom: 0;
	}

	.gallery .slider-btns button {
		height: 50px;
		width: 50px;
		border-radius: 0;
		margin: 0;
		background-color: #4097ee;
		border: none;
		color: #fff;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		position: relative;
	}

	.gallery .slider-btns button:hover {
		background-color: #2062a6;
	}

	.gallery .slider-btns button:first-child {
		margin-right: 2px;
	}

	.gallery .slider-btns button::before {
		content: "";
		position: absolute;
		top: 52%;
		left: calc(50% - 7px);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 14px;
		height: 2px;
		background-color: #fff;
	}

	.gallery .slider-btns button::after {
		content: "";
		position: absolute;
		top: 52%;
		left: calc(50% - 6px);
		width: 12px;
		height: 12px;
		border-bottom: 2px solid #fff;
		border-left: 2px solid #fff;
		background-color: transparent;
		-ms-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
	}

	.gallery .slider-btns button:last-child::after {
		border: none;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
	}

	.row .info {
		width: 50%;
		padding: 0 50px;
	}

	.events-section {
		margin: auto;
		max-width: 820px;

		.events .event .btnWrap {
			margin-top: 0;
			height: 60px;
		}

		.events .event .btnWrap a {
			background-color: #1e5ba1;
			padding: 0 70px 0 25px;
			line-height: 60px;
			font-size: 16px;
		}
	}

	.events {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 60px -30px;
	}

	.events .event {
		width: 50%;
		padding: 0 30px;
	}

	.events .event img {
		width: 100%;
		height: 230px;
		object-fit: cover;
	}

	.events .event h4 {
		font-size: 16px;
		font-weight: bold;
		font-family: "Open Sans", sans-serif;
		margin: 12px 0;
		color: #414141;
	}

	.events .event p {
		font-size: 14px;
		line-height: 26px;
		margin: 12px 0 24px;
	}

	.success-section h2 {
		max-width: 820px;
		margin: 120px auto 0;
	}

	.success-section .snippet {
		max-width: 820px;
	}

	.people {
		min-height: 350px;
		position: relative;
	}

	.people .sub-title {
		font-size: 24px;
		font-family: "Open Sans", sans-serif;
		font-weight: bold;
		line-height: 34px;
		color: #1e5ba1;
		margin-bottom: -34px;
	}

	.person {
		display: flex;
		min-height: 350px;
		transition: all 0.2s ease-in-out;
		z-index: 1;
		overflow: hidden;
	}

	.person .info {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.person .info h3 {
		font-family: "Open Sans", sans-serif;
		color: #414141;
		font-size: 36px;
		line-height: 46px;
		margin-bottom: 12px;
	}

	.carouselSlide,
	.carouselSlide .slick-track {
		height: auto;
	}

	.carousel-dots {
		bottom: 0;
		left: 0 !important;
	}

	.carousel-dots .slick-dots {
		padding: 0 !important;
	}

	.person .gallery {
		width: 50%;
		height: 350px;
	}

	.slick-dots li {
		border: 2px solid #1e5ba1;
	}

	.slick-dots li.slick-active {
		border-color: #f4a536;
	}

	.bottom-banner {
		margin: 120px 0 0;
		min-height: 475px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.bottom-banner p {
		color: #fff;
		font-family: "ff-tisa-sans-web-pro";
		font-size: 56px;
		font-weight: bold;
		line-height: 66px;
		text-align: center;
		max-width: 820px;
	}
}

@media screen and (max-width: 990px) {
	.getInvolved {
		p,
		li {
			font-size: 14px;
		}

		.row {
			flex-direction: column;
			margin: 100px -50px;
		}

		.row:first-child {
			margin-top: 75px;
		}

		.gallery {
			width: 100% !important;
			height: 350px;
			order: 1 !important;
		}

		.people .gallery {
			padding: 0;
		}

		.people .gallery img {
			width: 100%;
		}

		.people .gallery .slider-btns {
			right: 0;
		}

		.row .info {
			width: 100%;
			margin-bottom: 50px;
		}

		.snippet {
			margin-bottom: 30px;
		}

		.people {
			min-height: 600px;
		}

		.people .sub-title {
			text-align: center;
		}

		.person {
			flex-direction: column;
			margin-top: 70px;
		}

		.person .info {
			width: 100%;
			margin-bottom: 25px;
		}

		.people-selector {
			top: 40px;
			right: 0;
			left: 0;
			text-align: center;
		}

		.bottom-banner p {
			font-size: 50px;
		}
	}
}

@media screen and (max-width: 500px) {
	.getInvolved {
		p,
		li {
			font-size: 12px;
		}

		.section-header {
			font-size: 42px;
		}

		.people .sub-title {
			font-size: 18px;
			margin-bottom: 0;
		}

		.info h3 {
			font-size: 18px;
		}

		.events {
			margin: 35px -30px;
		}

		.events .event {
			width: 100%;
			margin-bottom: 50px;
		}

		.bottom-banner p {
			font-size: 36px;
		}
	}
}

@media screen and (max-width: 360px) {
	.getInvolved .people-selector {
		top: 75px;
	}
}
