$font-main: ff-tisa-sans-web-pro, sans-serif;
$clr-blue: #3c70ac;

@mixin screen-min($px){
  $min-width: $px#{px};
  @media screen and (min-width: $min-width) {@content}
}

@mixin screen-max($px){
  $max-width: $px#{px};
  @media screen and (max-width: $max-width) {@content}
}

@mixin screen-from-to($from, $to){
  $min-width: $from#{px};
  $max-width: $to#{px};
  @media screen and (min-width: $min-width) and (max-width: $max-width) {@content}
}

.container_whole {
  overflow: hidden!important;
}

.row-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

&.page-template-page-freedrumcorps {
  .grecaptcha-badge {
    bottom: 60px!important;
    z-index: 99;
  }
}

.drumcorps_top-btn {
  text-align: center;
  padding: 50px 15px;
  box-shadow: 0 0 5px 3px rgba(0,0,0,0.2);
  @include screen-max(992) {
    display: none;
  }
}

.drumcorps_top-link {
  transition: all 0.5s ease;
  font: 700 18px/1.2 $font-main;
  padding: 10px 30px;
  display: inline-block;
  outline: none;
  text-decoration: none;
  background: $clr-blue;
  color: #ffffff;
  &:hover {
    background: #4d6a8c;
    color: #ffffff;
  }
}

.info_block {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: grey;
  padding: 60px 0 40px;
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.76);
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include screen-max(992) {
      display: block;
    }
  }
  .item {
    flex-basis: 680px;
    @include screen-max(1200) {
      margin: 0 20px;
    }
    @include screen-max(992) {
      flex-basis: 100%;
      margin: 0 auto;
    }
  }
  img {
    margin: 0 auto;
    max-width: 100%;
    padding:0 15% 0 0; 
    @include screen-max(992) {
      display: block;
      margin: 0 auto 50px;
      padding:0px;
    }
  }
  h1 {
    color: #fff;
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 1.6;
    font-family: ff-tisa-sans-web-pro;
    font-weight: 700;
    @include screen-max(992) {
      max-width: 100%;
      font-size: 20px;
    }
  }
  p {
    color: #fff;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    font-family: "Open Sans",sans-serif;
  }
}

.video_block {
  padding: 105px 0 80px;
  @include screen-max(992){
    padding: 60px 0 40px;
  }
  @include screen-max(767) {
    padding: 45px 0 40px;
  }
  h2 {
    color: #010151;
    a {
      color: #3a70ad;
    }
  }
  h3{
    color: #17579f;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    font-family: ff-tisa-sans-web-pro;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &::after{
      content: '';
      height: 2px;
      width: 100%;
      background-color: #ebebeb;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  p{
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    font-family: "Open Sans",sans-serif;
    color: #414141;
  }
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    @include screen-max(992) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }
  iframe {
    max-width: 100%!important;
    margin-bottom:10px; 
    //box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.76);
    @include screen-max(992) {
      margin: 10px auto 0;
    }
    @include screen-max(767) {
      height: 230px !important;
    };
  }
  .item {
    flex-basis: 660px;
    max-width: 50%;
    &:first-child {
      padding-right: 25px;
    }
    @include screen-max(992) {
      &:first-child {
        padding-right: 0;
      }
      padding-right: 0;
      max-width: 100%;
      margin: 20px auto;
    }
  }
  .big_text {
    text-align: center;
  }
  h2 {
    font-size: 40px;
    @include screen-max(767) {
      font-size: 24px;
    }
  }
  h3 {
    @include screen-max(767) {
      font-size: 20px;
    }
  }
  p:not(:last-of-type) {
    margin-bottom: 25px;
  }
  a {
    transition: all .4s ease;
  }
}

.drum_top {
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.76);
  text-align: center;
  padding: 120px 0 295px;
  color: #fff;
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    max-width: 700px;
    padding: 0;
    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  h3 {
    color: #F5C700;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4;
    font-family: ff-tisa-sans-web-pro,sans-serif;
    @include screen-max(767) {
      font-size: 20px;
    }
  }
  p{
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    font-family: "Open Sans",sans-serif;
  }
}
.drum_top + .container {
  max-width: 1370px;
}
.drum_items {
  margin: -230px -40px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .item {
    flex-basis: 330px;
    max-width: 330px;
    margin: 0 20px 100px;
  }
  h3 {
    color: #F5C700;
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4;
    font-family: ff-tisa-sans-web-pro,sans-serif;
    @include screen-max(767) {
      font-size: 20px;
    }
  }
  p{
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    font-family: "Open Sans",sans-serif;
    color: #414141;
  }
  .drum_image {
    box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.76);
    margin-bottom: 30px;
    img{
      display: block;
    }
    @include screen-max(767) {
      height: 310px;
      img {
        display: none;
      }
    }
  }
}

.join_top {
  height: 660px;
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.76);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  @include screen-max(992) {
    height: 240px;
  }
  @include screen-max(767) {
    height: 150px;
  }
}
.join {
  .container {
    max-width: 1377px;
    margin-top: -390px;
    background: #fff;
    padding-top: 120px;
    @include screen-max(992) {
      margin-top: 0;
      padding-top: 55px;
    }
  }
  ol{
    list-style: decimal;
    padding-left: 20px;
  }
  h2 {
    font-family: ff-tisa-sans-web-pro;
    font-size: 32px;
    color: #1e5ba1;
    font-weight: 700;
    line-height: 61px;
    max-width: 765px;
    margin: 0 auto 30px;
    @include screen-max(992) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  h4 {
    font-family: ff-tisa-sans-web-pro;
    font-size: 22px;
    color: #1e5ba1;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 25px;
  }
  li{
    font-family: 'Open Sans';
    font-size: 16px;
    color: #414141;
    line-height: 30px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: #414141;
    font-family: "Open Sans",sans-serif;
  }
  .wrapper {
    width: 715px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .btnWrap {
      a {
        padding-right: 95px;
      }
    }
    @include screen-max(767) {
      display: block;
      .btnWrap{
        width: 100%;
        a {
          font-size: 16px;
          padding-right: 40px;
          text-align: left;
        }
      }
    }
    .btnWrap{
      margin-bottom: 20px;
      &:hover a{
        background-color: #073f8c;
      }
    }
    a {
      background-color: #1e5ba1;
      font-family: 'Open Sans';
      font-size: 18px;
      color: #ffffff;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 1px;
      text-align: center;
      text-decoration: none;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //&::before{
      //  width: 25px;
      //  top: 46%;
      //  right: 20px;
      //  transform: translateY(-50%) rotate(90deg);
      //}
      //&::after{
      //  transform: translateY(-50%) rotate(135deg);
      //}
    }
  }
}


.image_block1,
.image_block2 {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 140px;
  @include screen-max(1200) {
    padding-right: 0;
    max-width: 92%;
    margin: 100px auto 0;
  }
  @include screen-max(992) {
    max-width: 100%;
    display: block;
    overflow: hidden;
    margin-top: 60px;
  }
  img {
    flex-basis: 48%;
    width: 48%;
    //box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.76);
    @include screen-max(992) {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
      flex-basis: 88%;
      width: 88%;
    }
    @include screen-max(599) {
      flex-basis: 100%;
      width: 100%;
    }
  }
  .contain {
    padding-left: 75px;
    max-width: 770px;
    @include screen-max(992) {
      margin: 60px auto 0;
      text-align: center;
      padding: 0;
    }
  }
  h2 {
    color: #17579f;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    font-family: ff-tisa-sans-web-pro;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    @include screen-max(992) {
      font-size: 24px;
      margin: 0 auto 30px;
      text-align: left;
    }
    &::after{
      content: '';
      height: 2px;
      width: 100%;
      background-color: #ebebeb;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  p{
    @include screen-max(992){
      text-align: left;
    }
  }
  p, a{
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 30px;
    color: #414141;
    margin-bottom: 40px;
    text-decoration: none;
    transition: .3s;
  }
  a{
    color: #64afed;
    &:hover{
      text-decoration: underline;
    }
  }
  .button {
    width: 348px;
    height: 80px;
    background-color: #1e5ba1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1px;
    text-decoration: none;
    position: relative;
    padding-right: 30px;
    transition: .3s;
    text-align: center;
    &:hover{
      background-color: #073f8c;
    }
  }
  .button::before,
  .button::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
  .button::before{
    width: 17px;
    height: 2px;
    background-color: #fff;
  }
  .button::after{
    width: 14px;
    height: 14px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    background-color: transparent;
    transform: translateY(-50%) rotate(45deg);
  }
  .button:hover{
    box-shadow: 0px 8px 10px rgba(0,0,0,0.54);
    text-decoration: none;
  }
}
.image_block2 .contain{
  padding-left: 0;
  padding-right: 75px;
  @include screen-max(992){
    padding: 0;
  }
}
.image_block2{
  margin-bottom: 50px;
  @include screen-max(992){
    margin-top: 0;
  }
}

.event_block {
  background: #010151;
  color: #fff;
  padding-top: 110px;
  @include screen-max(992) {
    border-top: none;
    border-bottom: none;
  }
  .container {
    max-width: 1377px;
  }
  h2 {
    color: #F5C700;
    text-align: center;
    margin: 0 auto 50px;
    font-family: ff-tisa-sans-web-pro;
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
  }
  p{
    font-family: 'Open Sans';
    font-size: 16px;
    color: #ffffff;
    text-decoration: none solid rgb(65, 65, 65);
    line-height: 30px;
    margin-bottom: 20px;
  }
  h3 {
    color: #F5C700;
    margin-bottom: 15px;
    font-family: ff-tisa-sans-web-pro;
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    @include screen-max(992) {
      font-size: 24px;
    }
  }
  p a {
    color: #fff;
    font-size: 16px;
    font-family: 'Open Sans';
    transition: .3s;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}
.event_top {
  display: flex;
  justify-content: space-between;
  width: 950px;
  max-width: 100%;
  margin: 0 auto 80px;
  @include screen-max(992) {
    display: block;
    max-width: 92%;
    margin: 0 auto 55px;
  }
  h2 {
    font-size: 56px;
    color: #010151;
    //max-width: 550px;
    margin-bottom: 50px;
  }
  p {
    padding-bottom: 25px;
  }
  div {
    @include screen-max(992) {
      margin: 0 auto;
    }
  }
}
.event_top .btnWrap{
  margin-left: 30px;
  width: 335px;
  max-width: 100%;
  @include screen-max(992){
    margin-left: 0;
    a {
      padding-right: 60px;
    }
  }
}
//.event_top .btnWrap:last-child{
//  background-color: #1e5ba1;
//  margin-top: 20px;
//  &:hover{
//    background-color: #073f8c;
//  }
//}
.donate-value{
  text-align: center;
  margin-bottom: 70px;
}

.xtfacebook_events.xtfe_containter {
  max-width: 680px;
  margin: 20px auto;
  padding-right: 20px;
  @include screen-max(992) {
    padding-right: 0;
  }
  > * {
    width: auto !important;
  }
  .img_placeholder {
    display: none !important;
  }
  .wepa_event {
    height: auto !important;
  }
  .event_details {
    background-color: transparent;
    height: auto;
    border: 2px solid #fff;
    margin-top: -2px;
    @include screen-max(992) {
      display: flex;
      & > a {
        align-self: center;
      }
    }
  }
  .event_date {
    background-color: transparent;
    padding: 50px;
    width: 160px;
    border-right: 2px solid #fff;
    @include screen-max(992) {
      padding: 20px 20%;
      width: auto;
      align-self: center;
      border-right: none;
    }
    .month {
      font-size: 23px;
      line-height: 32px;
      text-transform: uppercase;
      text-align: center;
      color: #f70000;
      font-weight: 400;
      @include screen-max(992) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    .date {
      font-size: 39px;
      line-height: 32px;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      font-weight: 400;
      @include screen-max(992) {
        font-size: 25px;
        line-height: 20px;
      }
    }
  }
  .event_desc {
    width: auto !important;
    text-align: left;
    padding: 30px 10%;
    max-width: 67%;
    @include screen-max(992) {
      padding: 15px 5%;
      border-left: 2px solid #fff;
      max-width: 80%;
    }
    & > div {
      font-size: 16px;
      line-height: 32px;
      color: #bababa;
      @include screen-max(992) {
        font-size: 14px;
      }
    }
    a {
      font-size: 20px;
      line-height: 32px;
      font-weight: bold;
      color: #fff;
      @include screen-max(992) {
        font-size: 16px;
      }
    }
  }
  .archive-event {
    margin-bottom: 0 !important;
  }
}

.event_items {
  display: flex;
  justify-content: space-between;
  @include screen-max(992) {
    display: block;
  }
  .container {
    @include screen-max(992) {
      padding: 0 !important;
    }
  }
  > div {
    &:first-of-type {
      display: block;
      text-align: center;
      width: 50%;
      @include screen-max(992) {
        width: 100%;
      }
    }
    &:nth-of-type(2) {
      //flex-basis: 500px;
      max-width: 500px;
      text-align: center;
      position: relative;
      width: 45%;
      @include screen-max(992) {
        margin: 30px auto 0;
        width: 100%;
      }
    }
  }
  .small_line {
    display: block;
    margin: 0 auto;
    background: #eae8e6;
    width: 54px;
    height: 12px;
    @include screen-max(992) {
      margin: 50px auto;
      width: 100%;
    }
  }
  img {
    margin-top: 20px;
    width: 100%;
  }
}
.social_links {
  margin: 100px 0 0;
  text-align: center;
  padding-bottom: 70px;
  @include screen-max(992) {
    margin: 65px 0 0;
  }
  h3 {
    margin-bottom: 75px;
    @include screen-max(992) {
      margin-bottom: 40px;
    }
  }
  .social_items a{
    width: 50px;
    height: 50px;
    font-size: 30px;
    transition: .3s;
    text-decoration: none;
    &:hover{
      background-color: #f4a636;
    }
  }
}
.social_items {
  display: flex;
  justify-content: space-between;
  width: 470px;
  max-width: 100%;
  margin: 0 auto;
  @include screen-max(992) {
    width: 300px;
  }
  a {
    font-size: 56px;
    position: relative;
    height: 120px;
    width: 120px;
    background: #fff;
    border-radius: 50%;
    @include screen-max(992) {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #010151;
    }
  }
}

.sponsors {
  padding: 80px 0 110px;
  h3 {
    text-align: center;
  }
  img {
    max-width: 100%;
    max-height: 100px;
    width: auto;
    margin: 0 auto;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    text-align: center;
    height: 100px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .drum_sponsors {
    margin: 50px 0 0;
  }
}

.drum_menu {
  background: #009dac;
  text-align: center;
  padding: 45px 0!important;
  @include screen-max(767) {
    padding: 20px 0 10px!important;
  }
  .container {
    width: 1366px;
    max-width: 92%!important;
  }
  #menu-drum-menu {
    display: flex;
    justify-content: space-around;
    margin: 0;
    @include screen-max(767) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
  .menu-item {
    width: auto !important;
    margin-right: 20px;
  }
  a {
    color: #9AF0FF !important;
    font-weight: 700;
    font-size: 18px;
    @include screen-max(767) {
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 1200px) {
  .drumcorps_top-btn {
    padding: 70px 0;
  }

  .drumcorps_top-link {
    font: 700 24px/1.2 $font-main;
    padding: 30px 60px;
    margin-top: 65px;
  }
}

.sponsors + .apply{
  height: auto;
  padding: 10px 0;
  .apply__container{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.drumWrap ul{
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    li{
      margin-right: 10px;
    }
  }
}
.drumWrap ul a{
  font-family: ff-tisa-sans-web-pro;
  font-size: 16px;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: auto;
  font-weight: bold;
  transition: .3s;
}
.drumWrap ul a:hover{
  color: #F4A536;
}

.events__wrap{
  display: flex;
  margin-bottom: -2px;
}
.ecs-event{
  order: 2;
  margin-left: -2px;
  width: 100%;
  text-align: left;
}
.ecs-event a{
  font-family: ff-tisa-sans-web-pro;
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}
.ecs-event,
.events__date{
  border: 2px solid #fff;
  padding: 30px 30px 50px;
}
.events__date{
  font-size: 22px;
  padding: 40px;
  font-family: ff-tisa-sans-web-pro;
}
.events__date:first-line{
  color: red;
  text-transform: uppercase;
  font-size: 18px;
}
.duration.time{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #fff;
  line-height: 23px;
}
.event_items .item:first-child{
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans';
  position:relative;
  margin:0 auto;
}
.drumMenuWrap.apply,
.drumMenuWrap .applyFix.fixed{
  background: #1e5ba1;
}


.topButton{
  margin:20px 0 30px 0; 
}

@media (max-width: 767px) {
  .ecs-event{
    padding: 15px 15px 25px;
  }
  .events__date{
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .video_block{
    padding-top: 20px;
  }
  .drumMenuWrap .applyFix.fixed{
    
  }

  .fb_iframe_widget > span {
    width: 320px!important;
  }
}
