//.newsPage{
//  max-width: 1200px;
//  margin: 0 auto 100px;
//  .banner{
//    margin-bottom: 150px;
//  }
//  .tab-bar .nav-tabs {
//    display: flex;
//    justify-content: space-between;
//    margin-bottom: 40px;
//    margin-left: -50px;
//
//    .current-menu-item{
//      a{
//        background: none!important;
//      }
//    }
//
//    li{
//      width: 20%;
//      border-right: 1px solid #a3a3a3;
//      &:last-of-type{
//        border: none;
//      }
//      a{
//        font-family: ff-tisa-sans-web-pro;
//        font-size: 16px;
//        color: #1e5ba1!important;
//        line-height: 40px;
//        text-align: center;
//        text-decoration: none;
//        display: block;
//        margin: auto;
//      }
//    }
//  }
//}
.newsPage{
  padding-bottom: 55px;
  .post-border {
    .table-custom__info {
      .issuuembed.issuu-isrendered {
        display: none !important;
      }
    }
  }
}
.news__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 56px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
  margin-bottom: 70px;
}
.table-custom{
  display: flex;
  box-shadow: 0px 1px 12px rgba(0,0,0,0.2);
  margin-bottom: 45px;
}
.table-custom__img{
  width: 280px;
  box-shadow: 0px 1px 12px rgba(0,0,0,0.2);
  background-size: 100% auto;
  background-repeat:no-repeat;
  position: relative;
  background-position: center;
  &.contain-bg {
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.table-custom__img a{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.table-custom__info{
  width: 80%;
  padding: 35px 35px 70px;
  position: relative;
  min-height: 240px;
}
.news-title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}
.news-date,
.news-date a{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #1e5ba1;
  line-height: 30px;
  text-decoration: none;
  margin-bottom: 10px;
}
.news-content{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #404040;
  line-height: 21px;
  word-break: break-all;
}
.news-content a{
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  color: #64afed;
  transition: .3s;
  text-decoration: none;
}
.news-content a:hover{
  text-decoration: underline;
}
.news-content .news__btn,
.table-custom__info .news__btn{
  font-family: ff-tisa-sans-web-pro;
  font-size: 18px;
  color: #f4a636;
  font-weight: 700;
  line-height: 32px;
  position: absolute;
  bottom: 40px;
  left: 35px;
  text-decoration: none;
}
.news-content .news__btn:hover,
.table-custom__info .news__btn:hover{
  text-decoration: none;
}

.singlenews{
  border-bottom: 1px solid #4a4a4a;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.singlenews iframe{
  width: 100%;
}
.newsDetail__title li:not(:first-of-type){
  display: none;
}
.newsDetail__title a{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
  text-decoration: none;
}
.singlenews__title h1{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
}
.singlenews__title p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #1e5ba1;
  line-height: 30px;
}
.singlenews__title{
  margin-bottom: 35px;
}
.singlenews__bk{
  height: 425px;
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 870px;
  margin: 0 auto 35px;
  background-position: center;
}
.singlenews__content{
  margin: 0 auto 40px;
  line-height: 30px;
  h1, h2 {
    font-size: 30px;
    margin-bottom: 15px;
    line-height: 40px;
    font-family: ff-tisa-sans-web-pro;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: ff-tisa-sans-web-pro;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: ff-tisa-sans-web-pro;
  }
  h6, li {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    color: #414141;
  }
}
.wp-caption-text{
  font-size: 14px;
  font-family: 'Open Sans';
}
.singlenews__content,
.singlenews__content p,
.singlenews__content h5{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  margin-bottom: 0;
}
.singlenews__content a{
  text-decoration: none;
  color: #64afed;
  transition: .3s;
  font-size: 16px;
  line-height: 30px;
  font-family: 'Open Sans';
}
.singlenews__content a:hover{
  text-decoration: underline;
}
.singlenews__content img{
  max-width: 100%;
  min-height:950px;
  max-height:1000px; 
  height: auto;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.singlenews__content table.right img {
  margin-bottom: 15px;
  margin-left: 15px;
}
.btnBlue{
  background-color: #1e5ba1;
  margin: 20px auto;
}
.btnBlue:hover{
  background-color: #073f8c;
}
.btnBlue:hover a{
  text-decoration: none;
}
.btnBlue a{
  color: #ffffff;
}
//.singlenews center ~ a{
//  width: 348px;
//  height: 80px;
//  background-color: #f4a536;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  transition: .3s;
//  cursor: pointer;
//}
.echo_share{
  width: 350px;
  margin: auto;
  text-align: center;
}
.echo_share span{
  display: block;
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
  margin-bottom: 10px;
}
.echo_share ul{
  display: flex;
  justify-content: space-between;
}
.echo_share i{
  font-size: 30px;
  color: #1e5ba1;
  transition: all .25s;
  &:hover,
  &:focus{
    color: #F4A536;
  }
}

@media (max-width: 767px) {
  .table-custom{
    display: block;
  }
  .table-custom__img,
  .table-custom__info{
    width: 100%;
  }
  .table-custom__img{
    height: 250px;
  }
  .singlenews__title h1{
    font-size: 28px;
    line-height: 36px;
  }
  .singlenews__content{
    table{
      margin-left: 0!important;
    }
    .wp-caption.alignright{
      width: auto!important;
    }
  }
  .singlenews__content img{
    min-height:500px;
    max-height:550px;
  }
  .news__title{
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 600px) {
  .echo_share{
    width: auto;
  }
}

@media print {
  header,
  .sharepost,
  .footer__btn.btnWrap,
  footer .social-media,
  .nav.nav-tabs,
  .evemtmobile--menu,
  .archive-menu{
    display: none!important;
  }
  #hight-priority .container_whole {
    margin-top: 0!important;
  }
  #hight-priority .newsPage,
  #hight-priority .singlenews{
    padding-bottom: 0!important;
  }
  #hight-priority .footer__container {
    padding-top: 0!important;
  }
}

.issuuembed.issuu-isrendered {
  width: 100%!important;
  height: 1100px!important;
  @media (max-width: 991px) {
    height: 750px!important;
  }
  @media (max-width: 499px) {
    height: 400px!important;
  }
}