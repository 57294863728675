.footer{
  background-color: #f5f5f5;;
}
.footer__container{
  max-width: 1670px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}
.footer__logo{
  width: 20%;
  img{
    max-width: 100%;
    width: 160px;
    height: 162px;
  }
}
.footer__info{
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.footer__contact{
  width: 40%;
}
@media (min-width: 1440px) {
  .footer__info {
    width: 84%;
  }
  .footer__logo{
    width: 16%;
  }
  .footer__contact{
    width: 45%;
  }
}

.footer__contact a,
.footer__contact p,
.footer__organization,
.footer__organization a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #1e5ba1;
  line-height: 24px;
  text-decoration: none;
}
.footer__info a {
  transition: all .25s;
  &:hover,
  &:focus {
    color: #162636;
  }
}
.footer__contact p{
  margin-bottom: 25px;
}
.footer__contact p:first-of-type{
  margin-bottom: 7px;
}
.footer__contact p:last-of-type{
  margin-bottom: 7px;
}
.footer__organization{
  width: 25%;
}
.footer__social{
  width: 30%;
  text-align: right;
}
.footer__social .siteCreated{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #1e5ba1;
  line-height: 24px;
  text-decoration: none;
}
.footer .social-media{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}
.footer .social-media li{
  margin-left: 40px;
  transition: .3s;
}
.footer .social-media li:hover{
  transform: translateY(-5px);
}
.footer .social-media i{
  font-size: 30px;
  color: #f4a536;
}
.footer__social .footer__btn{
  width: 300px;
  height: 59px;
  background-color: #1e5ba1;
  margin-bottom: 20px;
  margin-left: auto;
  margin-top: 0;
  a {
    text-align: center;
  }
}
.footer__social .footer__btn:hover{
  background-color: #073f8c;
  a {
    color: #fff;
  }
}
.footer__social .footer__btn a{
  font-size: 16px;
  padding: 0;
  line-height: 59px;
}
.footer__social .footer__btn a::before,
.footer__social .footer__btn a::after,
.footer__mobile{
  display: none;
}
.footer__desktop{
  margin-bottom: 15px;
}

@media (max-width: 1750px) {
  .footer__container{
    max-width: none;
    padding: 50px 60px;
  }
}

@media (max-width: 1200px) {
  .footer__container{
    padding: 50px 140px;
  }
  .footer__container,
  .footer__mobile{
    display: block;
    text-align: center;
  }
  .footer__logo,
  .footer__info,
  .footer__contact,
  .footer__organization,
  .footer__social{
    width: 100%;
    text-align: center;
  }
  .footer .social-media{
    justify-content: center;
    margin-bottom: 0;
  }
  .footer .social-media li{
    margin: 0 15px;
  }
  .footer__social .footer__btn{
    margin: 0 auto 20px;
  }
  .footer__logo{
    margin-bottom: 35px;
  }
  .footer__info {
    flex-direction: column-reverse;
  }
  .footer__social{
    margin-bottom: 35px;
  }
  .footer__desktop{
    display: none;
  }
  .footer__organization{
    text-decoration: none;
    margin-bottom: 40px;
  }
  .footer__organization .footer__mobile{
    margin-bottom: 35px;
  }
  .footer__contact p,
  .footer__contact p:last-of-type{
    margin-bottom: 15px;
  }
  .footer__mobile p{
    margin-bottom: 7px;
  }
}

@media (max-width: 600px) {
  .footer__container{
    padding: 50px 20px;
  }
}
