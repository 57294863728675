.communityPage .banner{
  margin-bottom: 80px;
}
.communityPage .banner::before{
  background: linear-gradient(135deg, #008591 1%, #004a57 100% );
}
//.community__container{
//  max-width: 1470px;
//  margin: auto;
//}
.community-content{
  margin-bottom: 70px;
}
.community__items{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.community__item{
  width: 45%;
  margin-bottom: 40px;
  padding-top: 30px;
}
.serviceSlider .slick-dots{
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}
.serviceSlider .slick-dots li{
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
}
.serviceSlider .slick-dots li.slick-active{
  background-color: #fff;
}
.serviceSlider .slick-arrow{
  border: none;
  font-size: 0;
  background: transparent;
  width: 30px;
  height: 30px;
  border-left: 3px solid rgba(204,204,204,0.8);
  border-top: 3px solid rgba(204,204,204,0.8);
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%) rotate(-45deg);
  z-index: 10;
  transition: .3s;
  cursor: pointer;
}
.serviceSlider .slick-next{
  left: auto;
  right: 20px;
  transform: translateY(-50%) rotate(135deg);
}
.serviceSlider .slick-arrow:hover{
  border-color: #fff;
}
.serviceSlider .slick-arrow[aria-disabled="true"]{
  cursor: default;
  border-color: rgba(204,204,204,0.8);
}
.serviceSlider__img{
  position: relative;
}
.community__img{
  margin-bottom: 40px;
  max-height: 280px;
  overflow: hidden;
}
.community__img img{
  width: 100%;
  max-width: 100%;
  height: auto;
}
.community__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 24px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.community__title::after{
  content: '';
  height: 2px;
  width: 100%;
  background-color: #ebebeb;
  position: absolute;
  bottom: 0;
  left: 0;
}
.community__text{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
}
.dayServices__container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dayServices__left{
  width: 100%;
  margin-right: 70px;
}
.dayServices__info{
  width: 560px;
  background: linear-gradient(135deg, #483e5c 3%, #261f30 100% );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: none;
  padding: 80px 60px;
}
.dayServices h3{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
  margin-bottom: 15px;
}
.dayServices__left p,
.dayServices__left a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: .3s;
}
.dayServices .blueText{
  color: #1e5ba1;
  font-size: 18px;
}
.dayServices{
  margin-bottom: 60px;
}
.dayServices__left a:hover{
  color: #64afed;
}
.dayServices h5{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 23px;
}
.dayServices__info p,
.dayServices__info a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  transition: .3s;
}
.dayServices__info a:hover{
  color: #64afed;
}
.dayServices__info a.margin{
  margin-bottom: 20px;
  display: inline-block;
}
.startHere{
  margin-bottom: 40px;
}
.startHere__container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.startHere__left{
  width: 49%;
  padding: 50px 60px;
}
.startHere__right{
  width: 49%;
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  padding: 50px 60px;
}
.startHere__left h3,
.startHere__right h3,
.startHere__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}
.startHere__title{
  color: #414141;
}
.startHere__left p,
.startHere__right p,
.startHere__left li,
.startHere__right li,
.startHere h5,
.startHere a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  margin-bottom: 20px;
}
.startHere__right h3,
.startHere__right p,
.startHere__right li{
  color: #fff;
}
.startHere__right .startHere__text p:first-of-type{
  font-weight: 700;
}
.startHere a{
  color: #64afed;
  text-decoration: none;
  transition: .3s;
}
.startHere a:hover{
  color: #1e5ba1;
}
.startHere__right a{
  color: #ffffff;
  text-decoration: none;
}
.startHere__right a:hover{
  color: #ffffff;
  text-decoration: underline;
}
.startHere h5{
  font-weight: 700;
  margin-bottom: 10px;
}
.startHere__right li{
  margin-bottom: 5px;
  width: 48%;
  padding-left: 15px;
  position: relative;
}
.startHere__right li::before{
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 14px;
}
.startHere__right ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.startHere__right .facts li{
  width: 100%;
}
.startHere__right .facts li::before{
  display: none;
}
.startHere__right .facts li i{
  margin-right: 5px;
}
.startHere__right--wrap,
.startHere__left--wrap{
  margin-bottom: 30px;
  &.ttl-up {
    text-transform: uppercase;
  }
}
.startHere__right--wrap:last-child,
.startHere__left--wrap:last-child{
  margin-bottom: 0;
}




@media (max-width: 1550px) {
  //.community__container{
  //  max-width: none;
  //  padding: 0 50px;
  //}
}

@media (max-width: 1200px) {
  .dayServices__info{
    width: 47%;
  }
  .startHere__left,
  .startHere__right{
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .community__items,
  .dayServices__container{
    display: block;
  }
  .community__item,
  .dayServices__info{
    width: 100%;
  }
  .dayServices__left{
    width: 100%;
    margin: 0 0 30px;
  }
  .communityPage .banner {
    margin-bottom: 45px;
  }
  .startHere__container{
    display: block;
  }
  .startHere__right,
  .startHere__left{
    width: 100%;
  }
  .startHere__right {
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  //.community__container{
  //  padding: 0 20px;
  //}
  .dayServices__info{
    padding: 40px;
  }
  .startHere__right,
  .startHere__left{
    padding: 15px;
  }
  .startHere__left{
    padding-top: 0;
  }
  .community__item{
    padding-top: 0;
  }
}