.hfeed.site{
  opacity: 0;
  transition: .3s;
}

.site-header__container{
  display: flex;
  padding: 0;
  height: 146px;
  transition: .3s;
}
.site-header__logo,
.site-header__logo a{
  width: 115px;
  height: 108px;
  margin-right: 15px;
}

.site-header__logo a{
  display: flex;
  align-items: center;
}
.site-header__logo img{
  max-width: 100%;
  height: auto;
  width: 100%;
}
.site-header__top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-menu{
  display: none;
  position: absolute;
  background: #fff;
  padding: 15px 40px 35px;
  min-width: 300px;
  transform: translateX(-100px);
  top: 146px;
  box-shadow: 0 5px 8px -1px rgba(0,0,0,0.2),
              0 5px 8px -6px rgba(0,0,0,0.2);
  transition: .3s;
}
.sub-menu li{
  text-align: left;
  margin-bottom: 15px;
}
.sub-menu li:last-of-type{
  margin-bottom: 0;
}
.sub-menu a{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #162636;
  line-height: 24px;
  text-decoration: none;
  transition: .3s;
}
.sub-menu a:hover{
  color: #016fac;
}
.navbar-nav{
  display: flex;
  justify-content: space-between;
  li {
    &.current_page_item,
    &.current_page_ancestor {
      > a {
        position: relative;
        display: inline-block;
        &:after {
          left: 0;
          right: 0;
          height: 1px;
          content: '';
          bottom: -5px;
          background-color: #1e5ba1;
          position: absolute;
        }
      }
      .sub-menu {
        li {
          &.current_page_item {
            padding-right: 25px;
            a:after{
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
.navbar-nav > li{
  padding: 62px 0;
}
.site-header__nav{
  width: 85%;
  margin-right: 20px;
}
.navbar-nav > li > a{
  font-family: 'Open Sans';
  font-size: 14px;
  color: #1e5ba1;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  transition: .2s;
}
.navbar-nav > li > a:hover{
  color: #F4A536;
}
.site-header__search-wrap{
  display: none;
  position: absolute;
  left: 0;
  top: 138px;
  right: 0;
  width: 100%;
  background: #fff;
  padding: 40px 0;
  z-index: -1;
}
.site-header__search-wrap .btn-search{
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.site-header__search-wrap .btn-search i{
  color: #1e5ba1;
  font-size: 24px;
}
.site-header__search-wrap .custom-search-form{
  position: relative;
}

.search-field::-webkit-input-placeholder {font-family: 'Open Sans';font-size: 18px;line-height: 24px;letter-spacing: 1px;color: #1e5ba1;transition: .3s}
.search-field::-moz-placeholder {font-family: 'Open Sans';font-size: 18px;line-height: 24px;letter-spacing: 1px;color: #1e5ba1;transition: .3s}
.search-field:-ms-input-placeholder {font-family: 'Open Sans';font-size: 18px;line-height: 24px;letter-spacing: 1px;color: #1e5ba1;transition: .3s}
.search-field:-moz-placeholder {font-family: 'Open Sans';font-size: 18px;line-height: 24px;letter-spacing: 1px;color: #1e5ba1;transition: .3s}
.search-field:focus::-webkit-input-placeholder {color: transparent}
.search-field:focus::-moz-placeholder {color: transparent}
.search-field:focus:-moz-placeholder {color: transparent}
.search-field:focus:-ms-input-placeholder {color: transparent}

.site-header__search-wrap form{
  max-width: 1635px;
  margin: auto;
  .input-group-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 101;
  }
}
.screen-reader-text{
  display: none;
}
.search-field{
  width: 100%;
  border: none;
  border-bottom: 1px solid #1e5ba1;
  padding: 0 5px;
  height: 56px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.site-header__container {
  margin: 0 auto;
  max-width: 1670px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s;
}
.site-header__body{
  width: 100%;
  max-width: 1200px;
  text-align: right;
}
.navbar-header{
  display: none;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  z-index: 999;
}
.site-header__search-toogle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
  z-index: 5000;
  display: block;
  @media (max-width: 1350px) {
    height: auto;
    i.fa {
      height: auto;
    }
  }
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-search:before {
  content: "\f002";
}
.site-header__search-toogle i {
  position: absolute;
  //width: 14px;
  left: 50%;
  top: 35%;
  height: 15px;
  color: #1e5ba1;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  transform: translate(-50%, -50%);
}
.site-header__donate a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 50px;
  background-color: #f4a636;
  border-radius: 60px;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
  transition: .3s;
}
.site-header__donate a:hover{
  box-shadow: 0 4px 12px rgba(0,0,0,0.5);
  background-color: #F08400;
}
.navbar-collapse {
  position: static;
  height: auto !important;
}
.is-menu-open & {
  .site-header {
    background: #fff;
  }
  .navbar-collapse {
    opacity: 1;
    height: 100vh !important;
    display: block;
  }
  .navbar-toggle{
    background-color: rgba(12,36,63,0);
  }
  .navbar-toggle::before{
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
  .navbar-toggle::after{
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}
.navbar-toggle {
  width: 30px;
  height: 4px;
  background-color: #1e5ba1;
  border: none;
  position: relative;
  cursor: pointer;
  overflow: visible;
}
.menu-wrap{
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbar-toggle::before,
.navbar-toggle::after{
  content: '';
  width: 30px;
  height: 4px;
  background-color: #1e5ba1;
  position: absolute;
  left: 0;
  bottom: -10px;
  transition: .3s;
}
.navbar-toggle::before{
  bottom: auto;
  top: -10px;
}
.site-header__logo-big {
  display: inline-block;
}
.site-header__logo-sticky {
  display: none;
}
.site-header {
  margin: 0;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background: #fff;
  position: fixed;
  transition: all .35s;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
  //.is-menu-open &  {
  //  .site-header__logo-sticky {
  //    display: inline-block;
  //  }
  //  .site-header__logo-big {
  //    display: none;
  //  }
  //  .site-header__container {
  //    padding-top: 0;
  //    padding-bottom: 0;
  //  }
  //}
  &.is-scroll {
    background: #fff;
    .site-header__logo-big {
      display: none;
    }
    .site-header__logo-sticky {
      display: inline-block;
    }
    .site-header__container{
      padding: 0 20px;
      height: 116px;
      transition: .3s;
    }
    .site-header__search-wrap{
      top: 115px;
    }
    .sub-menu{
      top: 116px;
      box-shadow: 0 5px 8px -1px rgba(0,0,0,0.2),
                  0 5px 8px -6px rgba(0,0,0,0.2);
      transition: .3s;
    }
    .navbar-nav > li{
      padding: 46px 0;
    }
    .sub-menu::before{
      top: -40px;
    }
  }
  @media (max-width: 1350px) {
    &.is-scroll {
      .site-header__container{
        padding: 0 20px;
        background-color: #ffffff;
      }
      .navbar-header{
        right: 20px;
        cursor: pointer;
      }
      .site-header__search{
        margin-right: 35px;
      }
      .site-header__search-wrap,
      .navbar-collapse{
        top: 115px;
      }
    }
  }
}
.menu-item-3071 .sub-menu{
  column-count: 3;
}
.menu-item-3071 .sub-menu li{
  max-width: 250px;
}
.sub-menu::before{
  content: '';
  width: 1px;
  height: 20px;
  background-color: #162636;
  display: block;
  position: absolute;
  top: -52px;
  left: 150px;
  transition: .3s;
}
.menu-item-has-children.hover:hover > .sub-menu{
  display: block;
}

@media (max-width: 1750px) {
  .site-header__container{
    max-width: none;
    padding: 15px 40px;
  }
  .site-header__search-wrap form{
    max-width: none;
    padding: 0 125px;
  }
  .search-field{
    width: 100%;
    font-size: 20px;
  }
}

@media (max-width: 1350px) {
  .navbar-header{
    display: block;
  }
  .navbar-collapse{
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    top: 105px;
    background: #fff;
    opacity: 0;
    transition: .3s;
    display: none;
  }
  .navbar-nav{
    display: block;
    padding: 50px 70px;
    text-align: left;
    height: 80vh;
    overflow: scroll;
  }
  .site-header__nav .nav > .menu-item{
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
  }
  .site-header.is-scroll .navbar-nav>li{
    padding: 20px 0;
  }
  .site-header__container{
    box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
    height: auto;
  }
  .navbar-nav>li>a{
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
  }
  .sub-menu{
    position: static;
    transform: none;
    box-shadow: none;
    transition: none;
    padding: 10px 20px 0;
  }
  .sub-menu li{
    margin-bottom: 5px;
  }
  .sub-menu li:last-of-type{
    margin-bottom: 0;
  }
  .site-header.is-scroll .sub-menu{
    box-shadow: none;
    transition: none;
    padding: 10px 20px 0;
  }
  .checkThis::before{
    display: none;
  }
  .site-header__search{
    margin-right: 20px;
  }
  .navbar-header{
    right: 35px;
  }
  .menu-item-has-children{
    position: relative;
  }
  .menu-item-has-children .caret{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    z-index: 100;
    cursor: pointer;
  }
  .menu-item-has-children .caret::before{
    content: '';
    position: absolute;
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #1e5ba1 transparent transparent transparent;
    transition: .3s;
  }
  .menu-item-has-children .caret.changeArrow::before{
    transform: rotate(180deg);
  }
  .menu-item-3071 .sub-menu{
    column-count: auto;
  }
  .menu-item-3071 .sub-menu li{
    max-width: none;
  }
  .sub-menu::before{
    display: none;
  }
}

@media (max-width: 600px) {
  .site-header__container{
    padding: 15px 20px;
  }
  .site-header .site-header__search{
    margin-right: 35px;
  }
  .site-header .navbar-header {
    right: 20px;
  }
  .navbar-nav{
    padding: 30px;
  }
  .navbar-nav>li>a{
    font-size: 16px;
  }
  .site-header__logo,
  .site-header__logo a {
    width: 85px;
    height: 80px;
  }
  .site-header__donate a{
    height: 37px;
    margin-left: 0;
    padding-top: 2px;
  }
  .site-header.is-scroll .site-header__search-wrap,
  .site-header.is-scroll .navbar-collapse{
    top: 80px;
  }
  .site-header__search-wrap,
  .navbar-collapse{
    top: 110px;
  }
  .site-header.is-scroll .site-header__container{
    height: 96px;
  }
  .site-header__search-wrap form{
    padding: 0 20px;
  }
}

@media (max-width: 479px) {
  .site-header__search-toogle{
    width: 30px;
  }
}

@media (max-width: 349px) {
  .site-header__container,
  .is-scroll .site-header__container {
    padding-left: 15px;
    padding-right: 15px;
    .site-header__logo {
      width: 75px;
      a {
        width: 75px;
      }
    }
    .site-header__nav {
      order: 1;
    }
    .site-header__donate {
      a {
        margin-right: 15px;
      }
    }
  }
}