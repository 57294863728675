.career{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.career .banner__info{
  padding: 70px 40px 70px 5%;
}
.career .banner::before{
  background: linear-gradient(21deg, #008591 1%, #004a57 100% );
}
.career__img{
  width: 60%;
}
.career__info{
  width: 40%;
}
.career__img,
.career__img .slick-track{
  height: 525px;
}

.companySlider h2{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 22px;
}
.companySlider p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
}
.companySlider p.gold{
  font-family: ff-tisa-sans-web-pro;
  font-size: 16px;
  color: #f4a536;
  font-weight: 700;
  margin-bottom: 22px;
}
.companySlider,
.companySlider .slick-track,
.companySlider__img{
  height: 580px;
}
.companySlider__slide{
  position: relative;
}
.companySlider__slide{
  display: flex;
  align-items: center;
}
.companySlider__img,
.companySlider__info{
  width: 50%;
}
.companySlider__info{
  padding: 70px 80px;
  background-color: #004a57;
  height: 100%;
}
.companySlider .slick-dots {
  position: absolute;
  bottom: 60px;
  left: 80px;
  padding: 0;
}
.companySlider .slick-dots li{
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
}
.companySlider .slick-dots li.slick-active{
  background-color: #ffffff;
}
.reviews{
  background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
}
.reviews__container{
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 115px 0;
  position: relative;
}
.reviews__items{
  max-width: 800px;
  margin: auto;
}
.reviews__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
}
.reviews .slick-dots{
  margin: 20px auto 0;
  justify-content: center;
  padding: 0;
}
.reviews__info{
  width: 100%;
  margin: auto;
}

.reviews__info p{
  font-family: 'Open Sans';
  font-size: 20px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-bottom: 45px;
  position: relative;
  padding: 0 50px;
}
.reviews__info p::before,
.reviews__info p::after{
  content: '\201c';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
  font-weight: bold;
  color: #f4a536;
  font-family: 'Myriad Pro', Arial, sans-serif;
}
.reviews__info p::after{
  content: '\201d';
  left: auto;
  right: 0;
}
.testimonials_name b{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #f4a536;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  margin-bottom: 12px;
}
.testimonials_location{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #f4a536;
  line-height: 30px;
}
.reviews__btn .slick-arrow{
  border: none;
  background-color: transparent;
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  cursor: pointer;
  padding: 0;
}
.reviews__btn .slick-next{
  left: auto;
  right: -30px;
  transform: translateY(-50%) rotate(135deg);
}
.reviews .slick-dots li{
  width: 12px;
  height: 12px;
  border: 1px solid #fff;;
}
.reviews .slick-dots li.slick-active{
  background-color: #fff;
}
.featured{
  padding: 70px 0 60px;
}
.featured__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #414141;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 70px;
  text-align: center;
}
.apply{
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  height: 108px;
}
.apply__container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
}
.applyFix.fixed{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  z-index: 9999;
}
.apply__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
}
.apply__info p,
.apply__info a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  text-decoration: none;
  transition: .3s;
}
.apply__info a:hover{
  color: #64afed;
}
.apply__btn{
  width: 260px;
  height: 47px;
  margin-top: 0;
}
.btn.btn-apply{
  line-height: 47px;
}
@media (max-width: 1199px) {
  .apply{
    height: 100px;
  }
  .apply__container {
    height: 100px;
  }
  #hight-priority .apply__title {
    font-size: 20px;
  }
}
.together__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: 35px;
}
.together p{
  font-family: 'Open Sans';
  font-size: 20px;
  color: #1e5ba1;
  line-height: 30px;
  text-align: center;
}
.together{
  padding: 40px 0 40px;
  max-width: 800px;
  margin: 0 auto 45px;
}

.careerLadder{
  padding: 90px 0 50px;
  position: relative;
}
.careerLadder__bg1{
  background-position: center top, center 30px, center 60px;
  background-image: url("images/Path1.svg"), url("images/Path1.svg"), url("images/Path1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 10%;
  z-index: -1;
}
.careerLadder__bg2{
  background-position: center top, center 30px, center 60px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 60%;
  z-index: -2;
}
.careerLadder__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #483e5c;
  font-weight: 700;
  line-height: 61px;
  margin: 0 0 5px;
}
.careerLadder__description{
  font-family: Open Sans;
  font-size: 16px;
  color: #483e5c;
  line-height: 30px;
  max-width: 50%;
}
.careerLadder__item--title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 16px;
  color: #483e5c;
  font-weight: 700;
  line-height: 26px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
  margin-left: 15px;
}
.careerLadder__item--title::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #483e5c;
}
.careerLadder__item--desc{
  font-family: Open Sans;
  font-size: 14px;
  color: #414141;
  line-height: 23px;
  margin-bottom: 25px;
  margin-left: 15px;
}
.careerLadder__item--img{
  height: 88px;
  position: relative;
}
.careerLadder__item--bg{
  position: absolute;
  top: 31px;
  left: 0;
  width: 190px;
  height: 43px;
  opacity: 0.3;
  background-image: linear-gradient(90deg, #0591a3 1%, rgba(5, 145, 163, 0) 100% );
  z-index: -1;
}
.careerLadder__item{
  //transform: translate3d(0, 0, 0) rotate(15deg);
  margin-right: 50px;
  transform-origin: 0 0;
  transition: ease .5s;
  //-webkit-backface-visibility: hidden;
}
//.careerLadder__item.slick-active + .slick-active:last-child{
//  transform-origin: 2px -6px!important;
//}
.careerLadder__item:last-child{
  margin-right: 0;
}
.careerLadder .slick-track{
  //height: 600px;
  //height: 100%;
  padding: 200px 0 0;
  margin-bottom: -100px;
  //margin-left: -80px;
}
.careerLadder__items{
  //transform: translate3d(0, 0, 0) rotate(-15deg);
  padding-top: 300px;
  padding-bottom: 0;
  transform-origin: 0 0;
}
.careerLadder__item[aria-hidden="true"]{
  transform: translateY(0);
}
.careerLadder__item.slick-current{
  transform: translateY(-75px);
}
.careerLadder__item.slick-current + .slick-active{
  transform: translateY(-150px);
}
.careerLadder__item.slick-current + .slick-active + .slick-active{
  transform: translateY(-225px);
}
.careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active{
  transform: translateY(-300px);
}
.careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active{
  transform: translateY(-375px);
}
.careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
  transform: translateY(-450px);
}
.careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
  transform: translateY(-525px);
}
.careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
  transform: translateY(-600px);
}
.careerLadder__container.containerInner{
  padding: 0;
  overflow: hidden;
  position: relative;
}
.careerLadder__btn{
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.careerLadder__btn .slick-arrow{
  width: 102px;
  height: 102px;
  background-color: #f4a536;
  border: none;
  position: relative;
  transition: .3s;
  cursor: pointer;
}
.careerLadder__btn button::-moz-focus-inner {
  border: 0;
}
.careerLadder__btn .slick-arrow:hover{
  box-shadow: 0px 4px 9px rgba(0,0,0,0.3);
}
.careerLadder__btn .slick-next:hover{
  box-shadow: 0px -4px 9px rgba(0,0,0,0.3);
}
.careerLadder__btn .slick-prev{
  margin-right: 10px;
}
.careerLadder__btn .slick-next{
  transform: rotate(180deg);
}
.careerLadder__btn .slick-arrow::before{
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 0 20px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  left: 35px;
  top: 50px;
}
.careerLadder__btn .slick-arrow::after{
  content: '';
  width: 35px;
  height: 6px;
  background-color: #fff;
  position: absolute;
  top: 45px;
  left: 39px;
  transform: rotate(-45deg);
}
.careerLadder .slick-list{
  overflow: visible;
}
.careerLadder__prefer{
  display: flex;
  font-family: Open Sans;
  font-size: 14px;
  color: #0591a3;
  line-height: 23px;
  font-style: italic;
  margin-left: 15px;
}
.careerLadder__prefer p{
  margin: 0 5px 0 0;
}
.careerLadder__prefer li{
  position: relative;
}
.careerLadder__prefer li:not(:last-of-type)::after{
  content: ',';
  display: inline;
}

.benefits.values .values__item,
.benefits.values .values__item:nth-child(4n+4){
  width: 31%;
  margin-right: 3.5%;
}
.benefits.values .values__item:nth-child(3n+3){
  margin-right: 0;
}


@media (min-width: 2400px) {
  .career__img{
    width: 56%;
  }
  .career__img,
  .career__img .slick-track{
    height: 600px;
  }
  .careerLadder__container.containerInner{
    max-width: 2200px;
  }
}

@media (max-width: 2720px) {
  .careerLadder__item[aria-hidden="true"]{
    transform: translateY(0);
  }
  .careerLadder__item.slick-current{
    transform: translateY(-75px);
  }
  .careerLadder__item.slick-current + .slick-active{
    transform: translateY(-150px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active{
    transform: translateY(-225px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active{
    transform: translateY(-300px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active{
    transform: translateY(-375px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
    transform: translateY(-450px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
    transform: translateY(-525px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-slide{
    transform: translateY(-600px);
  }
  .careerLadder__items{
    padding-top: 300px;
  }
}

@media (max-width: 2320px) {
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-slide{
    transform: translateY(-525px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-slide + .slick-slide{
    transform: translateY(-600px);
  }
  .careerLadder__items{
    padding-top: 200px;
  }
}
@media (max-width: 1920px) {
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-slide{
    transform: translateY(-450px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-active + .slick-slide + .slick-slide{
    transform: translateY(-525px);
  }
  .careerLadder__items{
    padding-top: 150px;
  }
}
@media (max-width: 1550px) {
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-slide{
    transform: translateY(-375px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-active + .slick-slide + .slick-slide{
    transform: translateY(-300px);
  }
  .careerLadder__items{
    padding-top: 100px;
  }
}
@media (max-width: 1250px) {
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-slide{
    transform: translateY(-300px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-active + .slick-slide + .slick-slide{
    transform: translateY(-375px);
  }

}

@media (min-width: 2721px) {
  .careerLadder__container.containerInner{
    max-width: 2650px;
  }
}


@media (max-width: 1800px) {
  .careerLadder__container.containerInner{
    max-width: none;
    margin: 0 100px;
  }
}

@media (max-width: 1400px) {
  .companySlider__info{
    padding: 40px 40px 40px 50px;
  }
  .companySlider .slick-dots{
    left: 50px;
    bottom: 40px;
  }
  .reviews__container{
    max-width: none;
    padding: 100px 50px;
  }
  .reviews__btn .slick-arrow{
    top: 75%;
    left: 50px;
  }
  .reviews__btn .slick-next{
    left: auto;
    right: 50px;
  }
}

@media (max-width: 1200px) {
  .career .banner__info{
    padding: 40px 40px 30px;
  }
  .companySlider h2,
  .companySlider p.gold{
    margin-bottom: 15px;
  }
  .apply__title{
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .companySlider__slide,
  .career{
    flex-direction: column;
  }
  .companySlider{
    background-color: #004a57;
    padding: 40px 50px;
  }
  .companySlider__info,
  .companySlider__img,
  .career__img,
  .career__info{
    width: 100%;
  }
  .companySlider__info{
    order: 2;
    background-color: transparent;
    padding: 30px 0;
  }
  .careerPage .banner::before{
    width: 100%;
  }
  .together{
    max-width: none;
    padding: 40px 50px;
  }
  .companySlider,
  .companySlider .slick-track{
    height: auto;
  }
  .companySlider__img{
    height: 400px;
  }
  .companySlider .slick-dots{
    bottom: 0;
    left: 0;
  }
  .companySlider h2{
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .companySlider .slick-list{
    padding-top: 50px;
  }
  .apply__title{
    font-size: 18px;
    line-height: 24px;
  }
  .apply,
  .apply__container{
    height: auto;
    padding: 10px 0;
  }
  .apply__btn{
    width: 230px;
  }
  .careerLadder__btn .slick-arrow{
    width: 77px;
    height: 77px;
  }
  .careerLadder__description{
    max-width: none;
  }
  .careerLadder__container.containerInner{
    margin: 0 50px;
  }
  .careerLadder__btn .slick-arrow::before{
    left: 20px;
    top: 40px;
  }
  .careerLadder__btn .slick-arrow::after{
    top: 35px;
    left: 25px;
  }
  .careerLadder__bg1{
    top: 50%;
  }
  .careerLadder__bg2{
    top: 75%;
  }
  .careerLadder__item.slick-current + .slick-active + .slick-slide{
    transform: translateY(-225px);
  }
  .careerLadder__item.slick-current + .slick-active + .slick-slide + .slick-slide{
    transform: translateY(-300px);
  }
  .careerLadder .slick-track{
    margin-bottom: -50px;
  }
  .benefits.values .values__item,
  .benefits.values .values__item:nth-child(4n+4),
  .benefits.values .values__item:nth-child(3n+3){
    width: 48%;
    margin-right: 0;
  }

  .btn.btn-apply{
    padding-right: 60px;
  }
}

@media (max-width: 767px) {
  .apply__title{
    font-size: 14px;
    margin-bottom: 10px;
  }
  .apply__container{
    flex-direction: column;
    justify-content: center;
  }
  .apply__btn{
    width: 195px;
    height: 37px;
    font-size: 16px;
  }
  .btn.btn-apply{
    line-height: 37px;
  }
  .career__img,
  .career__img .slick-track {
    height: 350px;
    .slick-slide {
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
    }
  }
  .careerLadder__title{
    line-height: 48px;
  }
}

@media (max-width: 650px) {
  .careerLadder__item.slick-current + .slick-slide{
    transform: translateY(-150px);
  }
  .careerLadder__item.slick-current + .slick-slide + .slick-slide{
    transform: translateY(-225px);
  }
  .careerLadder__item{
    margin: 0 20px;
  }
  .careerLadder__btn{
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .careerLadder__container.containerInner{
    margin: 0 20px;
  }
  .careerLadder .slick-track{
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .careerLadder{
    padding: 60px 0 30px;
  }
  .careerLadder .slick-track{
    margin-bottom: 0;
  }
}

@media (max-width: 600px) {
  .reviews__container{
    padding: 50px 20px;
  }
  .featured{
    padding: 30px 0;
  }
  .featured__title{
    margin-bottom: 30px;
  }
  .companySlider{
    padding: 40px 20px;
  }
  .companySlider h2{
    font-size: 18px;
  }
  .together{
    padding-top: 0;
  }
  .benefits.values .values__item,
  .benefits.values .values__item:nth-child(4n+4),
  .benefits.values .values__item:nth-child(3n+3){
    width: 100%;
  }
}