.aboutUs .banner{
  margin-bottom: 110px;
}
.aboutUs .banner::before{
  //background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
  background: rgb(30,91,161);
  background: -moz-linear-gradient(-45deg, rgba(30,91,161,1) 0%, rgba(0,133,145,1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(30,91,161,1) 0%,rgba(0,133,145,1) 100%);
  background: linear-gradient(135deg, rgba(30,91,161,1) 0%,rgba(0,133,145,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5ba1', endColorstr='#008591',GradientType=1 );
}
.missionVision{
  position: relative;
  margin-bottom: 130px;
}
.missionVision__container{
  //max-width: 1670px;
  //margin: auto;
  display: flex;
}
.missionVision__left{
  width: 40%;
  position: relative;
  background-color: #fff;
  z-index: 10;
  padding: 35px;
}
.missionVision__right{
  width: 60%;
  @media (min-width: 992px) {
    background: none;
  }
}
.missionVision__right--img{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 35%;
}
.missionVision__item{
  margin-bottom: 25px;
}
.missionVision__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 24px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 7px;
}
.missionVision__left p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #1e5ba1;
  line-height: 30px;
}
.values{
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  padding: 90px 0;
}
.values__container{
  //max-width: 1670px;
  //margin: auto;
  //padding: 90px 0;
}
.values__items{
  display: flex;
  flex-wrap: wrap;
}
.values__item{
  width: 22%;
  margin-right: 4%;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.values__item:nth-child(4n+4){
  margin-right: 0;
}
.values__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 30px;
  margin-bottom: 55px;
  position: relative;
}
.values__title::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ebebeb;
  height: 1px;
  width: 100%;
}
.values__item h5{
  font-family: 'Open Sans';
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.values__item p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
}
.annualReport__container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 940px;
  margin: auto;
  padding: 95px 0 130px;
}
.annualReport__img{
  position: relative;
  width: 194px;
}
.annualReport__btn{
  background-color: #1e5ba1;
}
.annualReport__btn:hover{
  background-color: #073f8c;
}
.annualReport__img img{
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  image-rendering:-moz-crisp-edges;          /* Firefox        */
  image-rendering:-o-crisp-edges;            /* Opera          */
  image-rendering:-webkit-optimize-contrast; /* Safari         */
  transition: .3s;
}
.annualReport__img img:hover{
  box-shadow: rgba(0,0,0,0.5) 0px 7px 16px 0px;
}
.annualReport__info{
  width: 70%;
}
.annualReport__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 24px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
  margin-top: -10px;
}
.annualReport__text{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  margin-bottom: 35px;
}


.leadershipPage .banner__info h3::after{
  display: none;
}
.leadershipPage .banner::before{
  background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
}
.leadershipPage .banner{
  margin-bottom: 80px;
}
.leaders{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.leader_row {
  padding-top: 0;
  padding-bottom: 30px;
  //.row {
  //  width: 1600px;
  //  max-width: 94%;
  //  margin: 0 auto;
  //}
  h3 {
    text-align: center;
    margin-bottom: 35px;
    font-family: ff-tisa-sans-web-pro;
    font-size: 32px;
    color: #1e5ba1;
    font-weight: 700;
    line-height: 40px;
  }
}
.leader_row:last-child {
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  margin-top: 10px;
  padding-bottom: 0;
  padding-top: 0;
}
.leadershipPage .leader_row:last-child {
  padding-top: 85px;
  padding-bottom: 50px;
}
.leadershipPage .leader_row:nth-child(5) {
  .leader.for_img {
    //pointer-events: none;
  }
}
.leader_row:last-child .leaders{
  flex-wrap: wrap;
}
.leader_row:nth-last-child(2) .leaders{
  flex-wrap: wrap;
}
.leader_row:nth-last-child(2) .leaders .leader{
  width: 22%;
}
.leader_row:last-child h3,
.boardPage .leader_row h3{
  text-align: left;
  color: #ffffff;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 50px;
}
.leader_row:last-child h3::after,
.boardPage .leader_row h3::after{
  content: '';
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  position: absolute;
  bottom: 0;
  left: 0;
}
.leader_row:last-child .leaders{
  justify-content: flex-start;
}
.leader_row:last-child .leader{
  margin: 0 4% 50px 0;
  width: 22%;
  background-color: transparent;
  max-width: none;
  box-shadow: none;
  text-align: left;
  padding: 0;
}
.leader_row:last-child .leader:nth-child(8n+7){
  margin-right: 0;
}
.leader_row:last-child h5{
  font-family: 'Open Sans';
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  //text-transform: uppercase;
  margin-bottom: 10px;
}
.leader_row:last-child span{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
}
.leader {
  max-width: 288px;
  background: #fff;
  text-align: center;
  padding: 15px 1% 20px;
  box-shadow: 0px 1px 12px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 10px 50px;
  &:hover {
    box-shadow: 0 5px 10px 0 #777
  }
  &.for_img {
    margin-top: 110px
  }
  img {
    height: auto;
    width: 234px;
    max-width: 100%;
    border-radius: 100%;
    margin: -125px auto 23px;
  }
  h5 {
    font-family: ff-tisa-sans-web-pro, sans-serif;
    font-size: 24px;
    color: #1e5ba1;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    //text-transform: capitalize;
  }
  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #1e5ba1;
    line-height: 30px;
    text-align: center;
  }
}
.directors_row {
  .leader {
    cursor: default;
    &:hover {
      box-shadow: 0 3px 5px -1px #777
    }
  }
}
.hidden_content {
  display: none
}
.remodal {
  width: 1600px;
  padding: 75px;
  max-width: 92%;
  .popup_scroll {
    max-height: 500px
  }
  .popup_wrap {
    .left {
      width: 350px;
      margin-right: 40px;
      img {
        height: auto;
        max-width: 100%;
        border-radius: 100%;
        margin-bottom: 25px;
      }
    }
    .right {
      text-align: left;
    }
  }
  .remodal-close {
    top: 15px;
    left: auto;
    right: 20px;
    color: #1e5ba1;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 100%;
    transition: all 0.3s ease;
    &:before {
      width: 40px;
      line-height: 40px;
      content: '×';
      font-size: 60px;
      font-weight: 700;
      font-family: unset!important;
    }
    //&:hover {
    //  background: #1e5ba1;
    //}
    @media (max-width: 600px) {
      &:before {
        font-size: 40px;
      }
    }
  }

}
.mCSB_scrollTools {
  .mCSB_draggerRail {
    background: rgba(#fff, 0)
  }
  .mCSB_dragger .mCSB_dragger_bar {
    width: 10px;
    background: #a4a6a8;
  }
}
.popup_wrap{
  display: flex;
  justify-content: space-between;
}
.popup_wrap .left{
  flex: none;
}
.popup_wrap .right{
  width: 100%;
}
.popup_wrap .left h5{
  font-family: ff-tisa-sans-web-pro;
  font-size: 18px;
  font-weight: 700;
  color: #17579f;
  //text-transform: capitalize;
}
.popup_wrap .left span{
  color: #17579f;
  font-family: 'Open Sans';
  font-size: 16px;
}
.popup_wrap .right p{
  font-size: 16px;
  line-height: 1.8;
  font-family: 'Open Sans';
}

.boardPage .leader_row .leaders{
  justify-content: flex-start;
}
.boardPage .leader_row .leaders .leader,
.boardPage .leader_row:last-child .leader{
  box-shadow: none;
  text-align: left;
  width: 18%;
  margin: 0 2.5% 40px 0;
  padding: 0;
  max-width: none;
}
.boardPage .leader_row .leader:nth-child(10n+9),
.boardPage .leader_row:last-child .leader:nth-child(10n+9){
  margin-right: 0;
}
.boardPage .leader_row:last-child{
  background: none;
}
.boardPage .leader_row:last-child h3,
.boardPage .leader_row h3{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.boardPage .leader_row:last-child h5,
.boardPage .leader_row h5{
  font-family: 'Open Sans';
  font-size: 18px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 30px;
  //text-transform: uppercase;
  text-align: left;
}
.boardPage{
  padding-bottom: 100px;
}
.boardPage .leader_row:last-child .leader span{
  display: none;
}
.boardPage .banner__info h3::after{
  display: none;
}
.boardPage .banner::before{
  background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
}



@media (max-width: 1750px) {
  //.missionVision__container{
  //  max-width: none;
  //  padding: 0 50px;
  //}
  .values{
    padding: 70px 0;
  }
  //.values__container{
  //  max-width: none;
  //  padding: 70px 50px 0;
  //}
}

@media (max-width: 1200px) {
  .leader_row .leaders .leader,
  .leader_row:nth-last-child(2) .leaders .leader,
  .boardPage .leader_row:last-child .leader{
    width: 30%;
  }
  .leader_row:last-child .leader,
  .leader_row:last-child .leader:nth-child(8n+7),
  .boardPage .leader_row .leaders .leader,
  .boardPage .leader_row .leader:nth-child(10n+9),
  .boardPage .leader_row:last-child .leader:nth-child(10n+9){
    margin-right: 3.33%;
  }
  .leader_row:last-child .leader:nth-child(6n+5),
  .boardPage .leader_row .leader:nth-child(6n+5),
  .boardPage .leader_row:last-child .leader:nth-child(6n+5){
    margin-right: 0;
  }
  .leader_row{
    //padding-top: 50px;
  }
  .boardPage{
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .aboutUs .banner{
    margin-bottom: 40px;
  }
  .values__item{
    width: 48%;
    margin: 0 0 30px;
  }
  .values__items{
    justify-content: space-between;
  }
  .missionVision__right--img{
    background: none!important;
  }
  .missionVision__right{
    height: 500px;
    width: 100%;
  }
  .missionVision__left{
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .missionVision__container{
    display: block;
  }
  .missionVision{
    margin-bottom: 70px;
  }
  .annualReport__container{
    display: block;
    max-width: none;
    padding: 70px 50px;
  }
  .annualReport__img{
    width: 300px;
    margin: 0 auto 30px;
  }
  .annualReport__info{
    width: 100%;
  }
  .annualReport__title{
    margin-top: 0;
  }
  .leader_row .leaders .leader,
  .leader_row:nth-last-child(2) .leaders .leader,
  .boardPage .leader_row:last-child .leader{
    width: 47%;
  }
  .leader_row:last-child .leader,
  .leader_row:last-child .leader:nth-child(6n+5),
  .boardPage .leader_row .leaders .leader,
  .boardPage .leader_row .leader:nth-child(6n+5),
  .boardPage .leader_row:last-child .leader:nth-child(6n+5){
    margin-right: 4%;
  }
  .leader_row:last-child .leader:nth-child(4n+3),
  .boardPage .leader_row .leader:nth-child(4n+3),
  .boardPage .leader_row:last-child .leader:nth-child(4n+3){
    margin-right: 0;
  }
  .popup_wrap{
    display: block;
  }
  .remodal .popup_wrap .left{
    margin: 0 auto 40px;
  }
}

@media (max-width: 600px) {
  .values__item{
    width: 100%;
  }
  //.missionVision__container{
  //  padding: 0 20px;
  //}
  //.values__container{
  //  padding: 40px 20px 0;
  //}
  .values{
    padding: 40px 0;
  }
  .annualReport__container{
    padding: 50px 20px;
  }
  .leader_row .leaders .leader,
  .leader_row:nth-last-child(2) .leaders .leader,
  .boardPage .leader_row:last-child .leader{
    width: 100%;
  }
  .leader_row:last-child .leader,
  .leader_row:last-child .leader:nth-child(6n+5){
    margin-right: 0;
  }
  .leader_row h3{
    font-size: 26px;
  }
  .remodal{
    padding: 30px 15px;
  }
  .remodal .popup_wrap .left{
    width: auto;
  }
  .remodal .remodal-close {
    top: 0;
    right: 0;
    color: #4097ee;
    background: transparent;
    &:hover {
      background: transparent
    }
  }
  .mCSB_inside > .mCSB_container {
    margin-right: 15px
  }
  .mCSB_scrollTools {
    width: 5px;
    .mCSB_dragger .mCSB_dragger_bar {
      width: 5px
    }
  }
  .missionVision__right{
    height: 300px;
  }
  .leadershipPage .banner{
    margin-bottom: 40px;
  }
  .leader_row{
    padding-bottom: 0;
  }
}