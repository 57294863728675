.servicesPage{
  max-width: 1670px;
}
.servicesPage img{
  max-width: 100%;
  height: auto;
  width: 100%;
}
.youthService.banner::before{
  background: linear-gradient(135deg, #008591 1%, #004a57 100%);
}
.youth-top{
  display: flex;
}
.youth-top__text{
  width: 55%;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.youth-top__img{
  width: 45%;
}
.youth-top__title{
  font-family: ff-tisa-sans-web-pro;
  color: #17579f;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}
.youth-top__text p{
  color: #414141;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}
.free-services{
  text-align: center;
  padding: 80px;
  color: #ffffff;
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
}
.free-services h3{
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 15px;
  font-family: ff-tisa-sans-web-pro;
}
.free-services p{
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
  font-family: 'Open Sans';
}
.service-variations{
  padding: 80px 0;
}
.service-variations-custom{
  padding-top: 0;
}
.service-variations__img{
  width: 50%;
  margin-right: 50px;
}
.service-variations__container:nth-child(even) .service-variations__img{
  margin-right: 0;
  margin-left: 50px;
}
.service-variations__text{
  width: 50%;
  flex: none;
}
.service-variations__text .btn-dark-blue{
  background-color: #17579f;
  text-decoration: none;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 80px;
  position: relative;
  padding-right: 96px;
  padding-left: 36px;
  transition: .3s;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  height: 80px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 52%;
    right: 36px;
    transform: translateY(-50%);
  }
  &::before{
    width: 17px;
    height: 2px;
    background-color: #fff;
  }
  &::after{
    width: 14px;
    height: 14px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    background-color: transparent;
    transform: translateY(-50%) rotate(45deg);
  }
  @media (max-width: 767px) {
    padding-left: 24px;
    height: 60px;
    line-height: 60px;
  }
}
.youthServiceMenu__wrap{
  max-width: 1440px;
  margin: 0 auto;
}

.servicesPage .collapse.in {
  display: block;
  visibility: visible;
}
.servicesPage .collapse {
  display: none;
  visibility: hidden;
}
.servicesPage.containerInner .tab-services{
  margin-bottom: 0;
}
.servicesPage .tab-bar .nav-tabs li{
  width: 25%;
  display: flex;
}
.evemtmobile--menu {
  display: none;
}
.evemtmobile--menu a{
  color: #162636;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Open Sans';
  transition: .3s;
  border: 0;
}
.evemtmobile--menu li{
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
}
.evemtmobile--menu .panel-title{
  position: relative;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  font-family: ff-tisa-sans-web-pro;
  color: #17579f;
}
.evemtmobile--menu .panel-title::after{
  content: '';
  position: absolute;
  top: 13px;
  right: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #1e5ba1 transparent transparent transparent;
  transition: .3s;
}
.evemtmobile--menu .changeArrow.panel-title::after{
  transform: rotate(180deg);
}
.evemtmobile--menu .current-menu-item a{
  color: #016fac;
}
.tab-services{
  margin: 30px 0 45px;
}
.tab-services .current_page_item a{
  border-color: #1e5ba1!important;
}

.youthServicefixed{
  position: fixed;
  //top: 130px;
  left: 0;
  right: 0;
  background-color: #fff;
  //max-width: none;
  margin-top: 0!important;
  padding-bottom: 0!important;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
  width: 100%;
  max-width: none;
  z-index: 200;
  transition: top .35s;
  .nav.nav-tabs{
    margin-bottom: 10px!important;
  }
  .tab-services{
    margin-bottom: 0;
  }

}
.youthServicefixed::before{
  content: '';
  position: absolute;
  left: -50%;
  top: 0;
  bottom: 0;
  background-color: #fff;
  width: 50%;
  //box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
}
.youthServicefixed::after{
  content: '';
  right: -50%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  width: 50%;
  //box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
}
.youthServicefixed.services_wrap_container .tab-services,
.youthServicefixed .tab-services{
  height: auto;
  margin-top: 10px!important;
}
.youthServicefixed .tab-bar .nav-tabs{
  margin-bottom: 10px;
}
.youthServicefixed .evemtmobile--menu{
  margin: 15px 0!important;
}
.youthServicefixed .panel-title{
  margin: 0;
}

@media (max-width: 1750px) {
  .servicesPage{
    max-width: none;
    padding: 0 50px;
  }
}

@media (max-width: 1350px) {
  .tab-services{
    display: none;
  }
  .evemtmobile--menu{
    display: block;
    margin-bottom: 30px;
    margin-top: 185px;
  }
  .subMenu{
    display: none;
  }
}


@media (max-width: 991px) {
  .service-variations__img,
  .service-variations__text,
  .youth-top__text,
  .youth-top__img{
    width: 100%;
    margin: 0;
  }
  .service-variations__img{
    margin-bottom: 30px;
  }
  .servicesPage{
    margin-bottom: 80px;
  }
  .youthService + .servicesPage{
    margin-top: 80px;
  }
  .servicesPage .evemtmobile--menu .panel-title{
    text-align: center;
  }
  .service-variations{
    padding: 40px 0;
  }
  .free-services{
    padding: 40px;
  }
  .youth-top__text{
    padding: 0;
  }
  .youth-top{
    display: block;
    margin-bottom: 30px;
  }
  .services_wrap_container .service-variations h5::after{
    width: 100%!important;
  }
}

@media (max-width: 600px) {
  .free-services{
    padding: 20px;
  }
  .servicesPage{
    padding: 0 20px;
  }
  .evemtmobile--menu{
    margin-top: 145px;
  }
}







& .services_wrap_container {
  .service-bigwrap{
    .container{
      max-width: 1600px;
    }
  }
  .purple-bottom {
    padding: 40px 0;

    .img-responsive {
      display: block;
      margin: 0 auto;
    }

    h4 {
      color: #17579f;
    }
    p {
      line-height: 28px;
      color: #414141;
    }
  }
  .youth-blue-top {
    padding: 40px 15px;
    background-color: #e3f0fc;
    h3 {
      text-align: center;
      font-size: 29px;
      line-height: 34px;
      color: #17579f;
      font-weight: 700;
    }
  }
  .tab-services {
    height: 100px;
    .tab-bar {
      background: none;
      .container {
        height: 100%;
      }
    }
    .nav-tabs {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-radius: 0;
    }

    .nav-tabs li {
      width: 25%;
      height: 100%;
      border-radius: 0;
      &.active {
        a {
          background: #e3f0fc;
          color: #17579f;
        }
      }
      //a {
      //  border-radius: 0;
      //  height: 100%;
      //  display: flex;
      //  flex-direction: column;
      //  justify-content: center;
      //  background: transparent;
      //  color: #fff;
      //  font-size: 20px;
      //  line-height: 30px;
      //}
    }
  }
  .dark-blue {
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 0px;
    text-align: center;
    background: #17579f;
  }
  .list-content {
    text-align: center;
    padding: 40px 0px;
  }
  .services-banner {
    background-color: #4097ee;
    padding: 70px 10px;
  }
  .services-small-banner {
    text-align: center;
    padding: 10px 40px;
    background-color: #17579f;
    max-width: 650px;
    margin: auto;
    box-shadow: 0 35px 80px #17579f;
  }
  .block-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service-variations {
    ul {
      &.column-list {
        column-count: 2;
        max-width: 565px;
        @media (max-width: 991px) {
          max-width: 100%;
        }
        @media (max-width: 767px) {
          column-count: 1;
        }
      }
    }
    li {
      margin-left: 10px;
      position: relative;
      padding-left: 15px;
      color: #111111;
      font-family: 'Open Sans';
      &:after {
        content: '';
        width: 4px;
        height: 4px;
        background-color: #404040;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
    p.ourservice {
      font-size: 20px;
      line-height: 30px;
      color: #404040;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      color: #414141;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 25px;
      font-family: 'Open Sans';
    }
    h5 {
      color: #17579f;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      font-family: ff-tisa-sans-web-pro;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 30px;
      &::after{
        content: '';
        height: 2px;
        width: 90%;
        background-color: #ebebeb;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .service-content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      padding: 0;
    }
  }

  @media (min-width: 992px) {
    .dark-blue {
      min-height: 373px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: stretch;
      justify-content: center;
      padding: 40px;
      margin-top: 40px;
      margin-bottom: 40px;
      text-align: center;
      background: #17579f;
    }
    .service-variations {
      .service-content-wrap {
        flex-direction: row-reverse;
      }
    }
    .service-variations:nth-of-type(2n) {
      .service-content-wrap {
        flex-direction: row;
      }
    }
    .services-small-banner {
      padding: 40px;
    }
  }

  @media (min-width: 1200px) {
    .purple-bottom {
      padding: 80px 0;
    }
    .youth-blue-top {
      padding: 80px 80px;
    }
    .list-content {
      padding: 80px 0px;
    }
    .dark-blue {
      padding: 80px;
      margin-top: 80px;
      margin-bottom: 80px;
      text-align: center;
      background: #17579f;
    }
  }
}





.youth-top-wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .service-content-color {
    background-color: #f4faff;
    h4 {
      margin-top: 0px;
      font-size: 29px;
      line-height: 40px;
    }
    p {
      color: #414141;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px;
      font-family: 'Open Sans', sans-serif;
    }
  }
}
.youth-white-block {
  background-color: #fff;
  .youth-white-block-wrap {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h5 {
      font-size: 29px;
      line-height: 34px;
      color: #17579f;
      margin-bottom: 15px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      color: #17579f;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}
.service-variations {
  .service-content-wrap {
    flex-direction: column-reverse;
    .service-content-color {
      padding: 0 15px;
    }
  }
  h5 {
    margin-bottom: 25px;
  }
}

.youth-list {
  width: 100%;
  li {
    margin-left: 0;
    width: 100%;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
    &.hidepoint {
      margin: 0;
      padding: 0;
      padding-top: 20px;
      padding-left: 10px;
      &:after {
        display: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}
@media (min-width: 992px) {
  .youth-list.list_cols ul {
    column-count: 2;
    -moz-column-count: 2;
  }
  .youth-top-wrap {
    flex-direction: row;
    align-items: stretch;
  }
  .service-variations {
    .service-content-wrap {
      flex-direction: column-reverse;
      .service-content-color {
        padding: 0 15px;
      }
    }
  }
}


.servicesPage .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height, opacity .35s ease;
  //@include transition-property(height, visibility);
  //@include transition-duration(.35s);
  //@include transition-timing-function(ease);
}


@media (max-width: 991px) {
  .service-variations .service-content-wrap .service-content-color {
    padding: 0;
  }
}