@import "reset";

.getStarted__container {
	max-width: 1670px;
	margin: auto;
	padding: 105px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.getStarted__info {
	max-width: 350px;
	margin-right: 50px;
}
.getStarted__title {
	font-family: ff-tisa-sans-web-pro;
	font-size: 24px;
	color: #f4a536;
	font-weight: 600;
	line-height: 32px;
	margin-bottom: 20px;
}
.getStarted__text,
.getStarted__text a {
	font-family: "Open Sans";
	font-size: 16px;
	color: #ffffff;
	line-height: 30px;
	text-decoration: none;
}
.getStarted__text a:hover {
	text-decoration: underline;
}
#hight-priority .getStarted__container .getStarted__link {
	margin-top: 0;
}
.getStarted__link a {
	font-family: "Open Sans";
	font-size: 18px;
	color: #ffffff;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 1px;
	text-decoration: none;
}

.getInformed__container {
	max-width: 1470px;
	margin: auto;
	display: flex;
	justify-content: space-between;
}
.getInformed__left,
.getInformed__right {
	width: 48%;
}
.getInformed__title {
	font-family: ff-tisa-sans-web-pro;
	font-size: 32px;
	color: #1e5ba1;
	font-weight: 600;
	line-height: 61px;
	margin-bottom: 15px;
}
.getInformed__container li a {
	font-family: "Open Sans";
	font-size: 20px;
	color: #1e5ba1;
	line-height: 37px;
	text-decoration: none;
	position: relative;
	transition: all 0.25s;
	&:hover,
	&:focus {
		color: #162636;
		&:before {
			background-color: #162636;
		}
	}
}
.getInformed__container ul {
	margin-bottom: 65px;
	padding-left: 40px;
}
.getInformed__container li a::before {
	content: "";
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #1e5ba1;
	position: absolute;
	top: 10px;
	left: -15px;
	transition: all 0.25s;
}

#hight-priority.page-id-122 .annualReport__container {
	flex-direction: row-reverse;
	padding-top: 0;
	max-width: 1120px;
}
#hight-priority.page-id-122 .annualReport__img {
	width: 317px;
}
#hight-priority.page-id-122 .annualReport__info {
	margin-right: 100px;
}
#hight-priority .containerInner {
	max-width: 1670px;
	margin: 0 auto;
	padding: 0 100px;

	.tab-bar .nav-tabs {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
		margin-left: -50px;

		.current-menu-item {
			a {
				background: none !important;
				border-color: #1e5ba1;
			}
		}

		li {
			width: 20%;
			border-right: 1px solid #a3a3a3;
			display: flex;
			&:last-of-type {
				border: none;
			}
			a {
				font-family: ff-tisa-sans-web-pro;
				font-size: 16px;
				color: #1e5ba1 !important;
				line-height: 40px;
				text-align: center;
				text-decoration: none;
				display: block;
				margin: auto;
				border-bottom: 1px solid transparent;
				transition: 0.3s;
				&:hover {
					border-color: #1e5ba1;
				}
			}
		}
	}
}
#hight-priority .containerInner .tab-services {
	margin-top: 185px;
}
#hight-priority .banner + .containerInner .tab-services,
#hight-priority .banner + .containerInner .evemtmobile--menu,
#hight-priority .newsPage .tab-services,
#hight-priority .newsPage .evemtmobile--menu {
	margin-top: 50px;
}
.page-nav {
	text-align: center;
}
.page-nav .wp-pagenavi a,
.page-nav .wp-pagenavi span {
	border: none;
	font-family: ff-tisa-sans-web-pro;
	font-size: 16px;
	color: #1e5ba1;
	font-weight: 600;
	line-height: 32px;
	text-align: center;
	margin: 0 15px;
}
.page-nav .pages {
	display: none;
}
.page-nav .wp-pagenavi a.disabledLink {
	color: #c4c4c4;
}
.page-nav .wp-pagenavi span.current {
	color: #f4a636;
}
#hight-priority .privatPolic {
	padding-top: 70px;
	padding-bottom: 40px;
	@media (max-width: 767px) {
		padding-top: 40px;
	}
}
.privatPolic__title {
	font-family: ff-tisa-sans-web-pro;
	font-size: 56px;
	color: #1e5ba1;
	font-weight: 600;
	line-height: 61px;
	margin-bottom: 50px;
	@media (max-width: 767px) {
		font-size: 36px;
		line-height: 42px;
	}
}
.privatPolic h4 {
	font-family: ff-tisa-sans-web-pro;
	font-size: 24px;
	color: #1e5ba1;
	font-weight: 600;
	line-height: 40px;
	margin-bottom: 7px;
}
.privatPolic p {
	font-family: "Open Sans";
	font-size: 16px;
	color: #414141;
	line-height: 30px;
	margin-bottom: 20px;
}
.page-template-page-getInvolved .community__img {
	max-height: none !important;
}

.page-search {
	display: flex;
	flex-direction: column;
	article {
		margin-bottom: 25px;
	}
	.entry-header {
		order: -2;
		.entry-title {
			font-size: 25px;
			margin-bottom: 5px;
		}
		h1 {
			padding-bottom: 20px;
			border-bottom: 1px solid #ebebeb;
		}
	}
	.page {
		order: -1;
	}
	.entry-title {
		a {
			text-decoration: none;
			font-family: ff-tisa-sans-web-pro;
			font-size: 26px;
			color: #1e5ba1;
			font-weight: 700;
			line-height: 40px;
			transition: all 0.3s;
			@media (max-width: 767px) {
				font-size: 18px;
				line-height: 26px;
			}
			&:hover {
				color: #64afed;
			}
		}
		mark {
			background: #fff;
			color: #64afed;
			padding: 0;
		}
	}
	.page-title {
		font-family: ff-tisa-sans-web-pro;
		font-size: 56px;
		color: #1e5ba1;
		font-weight: 700;
		line-height: 61px;
		@media (max-width: 767px) {
			font-size: 36px;
			line-height: 42px;
		}
		span {
			background: transparent;
			color: #64afed;
		}
		mark {
			background: transparent;
			color: #64afed;
		}
	}
	.entry-summary p {
		mark {
			background: #fff;
			color: #6daff2;
			padding: 0;
		}
	}
	.entry-meta {
		margin-bottom: 10px;
		a {
			font-family: "Open Sans";
			color: #414141;
			font-size: 14px;
			font-weight: 400;
			line-height: 23px;
			text-decoration: none;
		}
	}
}
.firstHead {
	margin-bottom: 25px;
}
//#hight-priority.tribe-events-page-template .hfeed.site{
//  opacity: 1;
//}

.userway.userway_p1 {
	bottom: 30px !important;
	top: auto !important;
	left: 40px !important;
}

body .userway.userway_p1.userway_hidden .userway_accessibility_icon {
	transform: translateX(-70px) !important;
}

body .userway.userway_p1 .userway_hide,
body .userway.userway_p2 .userway_hide,
body .userway.userway_p3 .userway_hide {
	right: auto !important;
	left: 40px !important;
}

html .userway.userway_p1 .userway_accessibility_icon .userway_hide span,
html body .userway.userway_p1 .userway_accessibility_icon .userway_hide span {
	border-radius: 0 4px 4px 0 !important;
}

@media (max-width: 1550px) {
	.getInformed__container {
		max-width: none;
		padding: 0 50px;
	}
}

@media (max-width: 1200px) {
	#hight-priority.page-id-122 .annualReport__container {
		max-width: none;
		padding: 20px 50px 60px;
	}
	#hight-priority .container_whole .containerInner {
		padding-left: 100px;
		padding-right: 100px;
	}
}

@media (max-width: 991px) {
	.getInformed__container {
		display: block;
	}
	.getInformed__left,
	.getInformed__right {
		width: 100%;
	}
	#hight-priority .container_whole .containerInner {
		padding-left: 50px;
		padding-right: 50px;
	}
	.getStarted__container {
		padding: 75px 50px;
	}
	.page-nav .wp-pagenavi a,
	.page-nav .wp-pagenavi span {
		margin: 0 2px;
	}
}

@media (max-width: 767px) {
	.getStarted__container {
		display: block;
	}
	.getStarted__info {
		max-width: none;
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
	.getStarted__info br {
		display: none;
	}
}

@media (max-width: 600px) {
	.getInformed__container {
		padding: 0 20px;
	}
	.getInformed__title {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 20px;
	}
	.getInformed__container ul {
		padding-left: 15px;
		margin-bottom: 40px;
	}
	.getInformed__container li a {
		font-size: 16px;
		line-height: 24px;
	}
	#hight-priority.page-id-122 .annualReport__container {
		padding: 0 20px 50px;
	}
	#hight-priority .container_whole .containerInner {
		padding-left: 20px;
		padding-right: 20px;
	}
	.getStarted__container {
		padding: 50px 20px;
	}
	#hight-priority .newsPage .tab-services,
	#hight-priority .newsPage .evemtmobile--menu {
		margin-top: 30px;
	}
}

@media (max-width: 479px) {
	.page-nav .wp-pagenavi a,
	.page-nav .wp-pagenavi span {
		margin: 0;
		font-size: 13px;
	}
}

#hight-priority {
	//@import "css/crisis_services";
	@import "css/header";
	@import "css/footer";
	@import "css/homePage";
	@import "css/pathways";
	@import "css/advertising";
	@import "css/community-and-family";
	@import "css/aboutUs";
	@import "css/contactUs";
	@import "css/news";
	@import "css/faces";
	@import "css/free-network";
	@import "css/history";
	@import "css/getInvolved";
	@import "css/career-opportunities";
	//@import "css/medicaid_service_coordination";
	//@import "css/page_community_family_support_services";
	//@import "css/page_contact_us";
	//@import "css/page_get_involved";
	//@import "css/page-dayservice";
	//@import "css/page-template-page-behavioral-health";
	//@import "css/page-template-page-housing-opportunities";
	//@import "css/page-template-page-NHTD";
	//@import "css/page-template-page-start-here";
	//@import "css/page-template-page-TBI";
	//@import "css/page-template-page-youth-education-services";
	@import "css/page-template-page-youth-services";
	@import "css/page-drum-corps";
	@import "css/page-arts";
	@import "css/style";
}
