.pathways .banner{
  margin-bottom: 80px;
}
.pathways .banner::before {
  background: linear-gradient(135deg, #008591 1%, #004a57 100% );
}
.pathways .banner__info h1 {
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}
.pathways .banner__info h2 {
  font-family: 'Open Sans';
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  margin-top: 22px;
  position: relative;
}
//.pathways .banner__info {
//  padding: 140px 60px;
//}
//.services-content__container{
//  max-width: 1670px;
//  margin: auto;
//}
.services-content__item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}
.services-content__img{
  width: 50%;
  max-height: 610px;
  overflow: hidden;
  margin-right: 50px;
}
.services-content__img img{
  max-width: 100%;
  height: auto;
  width: 100%;
}
.services-content__info{
  width: 50%;
  padding-right: 50px;
}
.services-content__item:nth-child(even) .services-content__info{
  padding-right: 0;
  padding-left: 50px;
}
.services-content__text p:last-of-type{
  margin-bottom: 0;
}
.services-content__info h3{
  font-family: ff-tisa-sans-web-pro;
  font-size: 24px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;
}
.services-content__info h3::after{
  content: '';
  height: 2px;
  width: 100%;
  background-color: #ebebeb;
  position: absolute;
  bottom: 0;
  left: 0;
}
.services-content__info p,
.services-content__info li{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
}
.services-content__info p{
  margin-bottom: 20px;
}
.services-content__info ul{
  list-style: disc;
  padding-left: 20px;
}
.services-content__item:nth-child(even){
  flex-direction: row-reverse;
}
.services-content__item:nth-child(even) .services-content__img{
  margin-right: 0;
  margin-left: 50px;
}
strong {
  font-weight: bold;
}
.service-affirmate{
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  padding: 130px 0 100px;
}
//.service-affirmate__container{
//  max-width: 1670px;
//  margin: auto;
//  padding: 130px 0 100px;
//}
.service-affirmate__items{
  display: flex;
  flex-wrap: wrap;
}
.service-affirmate__item{
  width: 22%;
  margin-right: 4%;
  margin-bottom: 55px;
}
.service-affirmate__item:nth-child(4n+4){
  margin-right: 0;
}
.service-affirmate__top{
  width: 50%;
  margin: 0 auto 100px;
}
.service-affirmate__top h5{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  margin-bottom: 30px;
}
.service-affirmate__top p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}
.service-affirmate__img img{
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.service-affirmate__title{
  font-family: 'Open Sans';
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.service-affirmate__text,
.service-affirmate__text a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
  text-decoration: none;
}
.service-affirmate__text a:hover{
  text-decoration: underline;
}
.service-offers{
  padding: 120px 50px 140px;
}
.service-offers__container{
  //max-width: 1670px;
  //margin: auto;
  display: flex;
  //padding: 120px 50px 140px;
}
.service-offers__info{
  width: 60%;
  margin-right: 100px;
}
.service-offers__info h6{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 32px;
}
.service-offers__info--title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 22px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 40px;
}
.service-offers__info--content p,
.service-offers__items h5{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  margin-bottom: 30px;
}
.service-offers__items h5{
  font-weight: 700;
  font-size: 22px;
}
.service-offers__info--content li{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
  margin-bottom: 5px;
  width: 40%;
}
.service-offers__info--content p,
.service-offers__info--content li{
  font-size: 18px;
}
.service-offers__info--content ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: disc;
  padding-left: 20px;
  @media (max-width: 991px) and (min-width: 767px) {
    li {
      font-size: 14px;
      word-break: break-word;
      line-height: 22px;
      width: 45%;
    }
  }
}
.service-offers__item{
  width: 329px;
  height: 80px;
  background-color: #483e5c;
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 20px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
  font-weight: 700;
}
.service-offers__item:nth-child(3){
  background-color: #625675;
}
.service-offers__item:nth-child(4){
  background-color: #80748f;
}
.service-offers__item:nth-child(5){
  background-color: #a196a8;
}
.service-offers__item:nth-child(6){
  background-color: #bdb8bf;
}
.services-content__text h6{
  font-family: ff-tisa-sans-web-pro;
  font-size: 18px;
  color: #414141;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 15px;
}
.services-content__container-custom{
  margin-top: 70px;
}
.paperback__container{
  width: 750px;
  background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
  padding: 50px 60px;
  margin: 0 auto 80px;
  text-align: center;
  color: #ffffff;
}
.paperback h5{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 23px;
}
.paperback p{
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
}
.paperback .blue_text_big{
  font-size: 20px;
  margin-bottom: 15px;
}
.paperback .blue_text_bold{
  font-weight: 700;
}
.together.housing p,
.together.housing .together__title{
  color: #414141;
}

@media (max-width: 1750px) {
  .services-content__container{
    max-width: none;
    padding: 0 50px;
  }
  //.service-affirmate__container,
  //.service-offers__container{
  //  max-width: none;
  //  padding: 80px 50px;
  //}
  .service-offers{
    padding: 70px 0;
  }
}

@media (max-width: 1200px) {
  .services-content__item{
    display: block;
  }
  .services-content__img,
  .services-content__info{
    width: 100%;
  }
  .services-content__item:nth-child(even) .services-content__img{
    margin: 0;
  }
  .services-content__info{
    padding: 30px 0 0;
  }
  .services-content__item:nth-child(even) .services-content__info{
    padding-left: 0;
  }
  .paperback__container{
    width: 100%;
  }
}

@media (max-width: 991px) {
  .service-affirmate__item{
    width: 30%;
    margin-right: 5%;
  }
  .service-offers{
    padding: 70px 0;
  }
  .service-affirmate__item:nth-child(4n+4){
    margin-right: 5%;
  }
  .service-affirmate__item:nth-child(3n+3){
    margin-right: 0;
  }
  .service-affirmate__top{
    width: 70%;
  }
  .service-affirmate{
    background: linear-gradient(20deg, #483e5c 3%, #261f30 100% );
    padding: 70px 0 30px;
  }
  .service-offers__info {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {

  .service-offers__container{
    display: block;
  }
  .service-offers__info,
  .service-offers__items{
    width: 100%;
    margin: 0 0 30px;
  }

  .service-affirmate__item,
  .service-affirmate__item:nth-child(4n+4){
    width: 48%;
    margin: 0 0 50px;
    //&:last-child {
    //  margin-bottom: 0;
    //}
  }
  .service-offers{
    padding: 70px 0 0;
  }
  .service-affirmate{
    padding: 60px 0 30px;
  }
  .service-affirmate__items{
    justify-content: space-between;
  }
  .paperback__container{
    padding: 20px;
  }
  .service-offers__container{
    padding-bottom: 40px;
  }
}

@media (max-width: 600px) {
  .services-content__container{
    padding: 0 20px;
  }
  .service-affirmate__container,
  .service-offers__container{
    padding: 50px 20px 30px;
  }
  .service-affirmate__item,
  .service-affirmate__item:nth-child(4n+4),
  .service-affirmate__top,
  .service-offers__info--content liv{
    width: 100%;
  }
  .service-offers__info--content ul{
    display: block;
  }
  .service-offers__info--content li{
    width: 100%;
  }
  .pathways .banner__info{
    padding: 40px 20px;
  }
  .banner__container{
    width: 100%;
  }
  .pathways .banner{
    margin-bottom: 40px;
  }
  .together.housing p{
    font-size: 16px;
    line-height: 24px;
  }
  .together.housing{
    padding-bottom: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  .services-content__container-custom{
    margin-top: 50px;
  }
}