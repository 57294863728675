.faces__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 56px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
  margin-bottom: 35px;
}
.faces__description{
  font-family: 'Open Sans';
  font-size: 20px;
  color: #1e5ba1;
  line-height: 30px;
}
.faces__info{
  margin-bottom: 70px;
}
.faces__items{
  display: flex;
  flex-wrap: wrap;
}
.faces__item{
  width: 23%;
  margin-right: 2.666%;
  box-shadow: 0px 1px 12px rgba(0,0,0,0.2);
  margin-bottom: 24px;
}
.faces__item:nth-child(4n+4){
  margin-right: 0;
}
.faceSingle__container{
  display: flex;
  justify-content: space-between;
}
.faceSingle__img{
  width: 40%;
  margin-right: 50px;
}
.faceSingle__info{
  width: 60%;
}
.faceSingle .row{
  display: flex;
}
.faces-fullpage__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 61px;
}
.faces-fullpage__intro p,
.faceSingle .row p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
}
.faceSingle__img figure{
  margin: 0;
}
.videoWrapper{
  margin-left: 50px;
  width: 40%;
  flex: none;
}
.faceSingle__stories{
  display: flex;
  justify-content: space-between;
}
.faceSingle__story{
  width: 49%;
}
@media (max-width: 1650px) {
  .youthServicefixed{
    padding: 0 100px;
    @media (max-width: 991px) {
      padding: 0 50px;
      @media (max-width: 600px) {
        padding: 0 20px;
      }
    }
  }
}


@media (max-width: 1200px) {
  .faces__item,
  .faces__item:nth-child(4n+4){
    width: 30%;
    margin-right: 3.33%;
  }
  .faces__item:nth-child(3n+3){
    margin-right: 0;
  }
  .faceSingle .row{
    flex-wrap: wrap;
  }
  .videoWrapper{
    width: 100%;
    margin: 30px 0 0;
  }
}

@media (max-width: 991px) {
  .faces__item,
  .faces__item:nth-child(4n+4){
    width: 48%;
    margin-right: 0;
  }
  .faces__items{
    justify-content: space-between;
  }
  .faceSingle__container{
    flex-wrap: wrap;
  }
  .faceSingle__img{
    width: 100%;
    margin: 0 0 40px;
  }
  .faceSingle__info{
    width: 100%;
  }
  .evemtmobile--menu .panel-title{
    display: block!important;
    text-align: center;
    &::after{
      right: 5px!important;
    }
  }
}

@media (max-width: 767px) {
  .faces__title{
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 600px) {
  .faces__item,
  .faces__item:nth-child(4n+4){
    width: 100%;
  }
}





.faces-page-item {
  display: block;
  width: 100%;
  height: 342px;
  background-size: cover !important;
  filter: grayscale(100%); /* Current draft standard */
  filter: gray; /* IE */
  cursor: pointer;
  transition: filter 0.5s;
  position: relative;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    filter: none; /* Current draft standard */
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
    filter: none; /* Current draft standard */
  }
}

.faces-page-item:hover {
  filter: grayscale(0);
}

.faces-page-item__title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 25px 20px;
  font-family: Open Sans;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  background-image: linear-gradient(180deg, rgba(235, 235, 235, 0) 2%, #000000 100%);
  background-position: center center;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++
 FACES OF FREE - FULL PAGE
 ++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.faces-fullpage-img {
  display: block;
  width: 100%;
  height: 584px;
  background-size: cover !important;
  margin: 0 0 70px 0;
}

.faces-fullpage__pub-info,
.backtoblog a{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #64afed;
  line-height: 30px;
  margin-bottom: 20px;
  display: block;
  text-decoration: none;
}

.faces-fullpage__share {
  display: block;
  margin: 60px 0;
}

.faces-fullpage__share > li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}
@media screen and (max-width: 767px) {
  .faces-fullpage__share > li:first-child {
    display: block;
    width: 100%;
    padding-bottom: 15px;
  }
}

.faces-fullpage__share > li:first-child {
  margin-right: 10px;
  font-family: Open Sans;
  font-size: 14px;
  color: #4097ee;
  font-weight: 600;
}

.faces-fullpage__share > li > a {
  width: 40px;
  height: 40px;
  background-color: #17579f;
  margin-right: 10px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  padding: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.faces-fullpage__other-story {
  padding: 0 0 60px;
}

.faces-fullpage__other-story-item {
  display: block;
  width: 100%;
  height: 487px;
  background-size: cover !important;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  position: relative;
  transition: filter 0.5s;
}

.faces-fullpage__other-story-item:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.faces-fullpage__other-story-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: Open Sans;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  padding: 20px;
  background-image: linear-gradient(180deg, rgba(235, 235, 235, 0) 2%, #000000 100%);
  background-position: center center;
  cursor: pointer;
}

/* Help style */
.mobile-image {
  display: none;
}

.bg-blue {
  background: #4097ee;
}

@media (min-width: 1450px) {

  //.nav-tabs li a {
  //  min-width: 160px !important;
  //}

}

/* MEDIA */

@media only screen and (max-width: 1023px) {

  .faces-fullpage-img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .faces-fullpage__share {
    text-align: center;
  }

  .faces-fullpage__intro {
    padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .faces-fullpage-img{
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .videoWrapperWrap,
  .videoWrapperWrap iframe{
    width: 100%;
  }
  .faces-fullpage__other-story{
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .faces-fullpage-img {
    display: none;
  }
  .faceSingle__img{
    margin: 0;
  }

  .mobile-image {
    display: block;
    margin: 0;
  }

  .faces-fullpage__title, .faces-fullpage__pub-info {
    width: 100%;
    margin: 0 auto 20px auto;
  }

  .mobile-faces-fullpage-img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin: 0 auto;
    display: block;
    background-size: cover !important;
    margin-bottom: 30px;
  }

  .faces-fullpage__intro {
    padding-left: 0px;
  }

  .faces-fullpage__share > li > a {
    width: 35px;
    height: 35px;
    font-size: 16px;
    padding: 6px;
    margin-right: 0px;
  }

  .faces-fullpage__other-story-item {
    height: 140px;
  }

  .faces-fullpage__other-story-title {
    font-size: 14px;
  }
}