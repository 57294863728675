.freeNetworkBannner.banner{
  margin-bottom: 90px;
}
.freeNetwork .banner::before{
  background: linear-gradient(21deg, #008591 1%, #004a57 100% );
}
.firstContent{
  position: relative;
}
.firstContent__container{
  max-width: 1670px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstContent__text{
  width: 55%;
}
.firstContent__img{
  width: 40%;
  height: 200px;
  @media (min-width: 991px) {
    background: none!important;
  }
  @media (min-width: 1360px) {
    min-height: 380px;
  }
}
.firstContent__img--bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 60%;
}
.firstContent__text p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
}
.partners{
  max-width: 1400px;
  margin: auto;
}
.partners__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 32px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 51px;
  text-align: center;
  padding: 60px 40px;
  margin-bottom: 80px;
}
.partners__item{
  display: flex;
  margin-bottom: 65px;
}
.partners__item:nth-child(even){
  flex-direction: row-reverse;
}
.partners__item:nth-child(even) .partners__img{
  margin-right: 0;
  margin-left: 50px;
}
.partners__img{
  min-width: 260px;
  max-width: 350px;
  flex: none;
  margin-right: 50px;
  position: relative;
  box-shadow: 0px 1px 11px rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.partners__img .visit_site{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.partners__img img{
  max-width: 100%;
  height: auto;
  width: 100%;
}
.partners__text{
  width: 100%;
}
.partners__text h2{
  font-family: ff-tisa-sans-web-pro;
  font-size: 24px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 25px;
}
.partners__text h2::after{
  content: '';
  width: 100%;
  height: 2px;
  background-color: #ebebeb;
  position: absolute;
  bottom: 0;
  left: 0;
}
.partners__text p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 30px;
}
.firstContent__container{
  max-width: 1670px;
  margin: 0 auto 55px;
}

@media (max-width: 1750px) {
  .firstContent__container{
    max-width: none;
    padding: 0 50px;
  }
}

@media (max-width: 991px) {
  .firstContent__container{
    display: block;
  }
  .firstContent__text{
    width: 100%;
    margin-bottom: 30px;
  }
  .firstContent__img{
    width: 100%;
  }
  .firstContent__img--bg{
    display: none;
  }
  .partners__item{
    display: block;
  }
  .partners__img,
  .partners__item:nth-child(even) .partners__img{
    margin: 0 auto 30px;
  }
  .partners__text{
    width: 100%;
  }
  .freeNetworkBannner.banner,
  .partners__title{
    margin-bottom: 50px;
  }
}

@media (max-width: 600px) {
  .firstContent__container{
    padding: 0 20px;
  }
  .partners__title{
    padding: 0;
  }
  .freeNetworkBannner.banner{
    margin-bottom: 40px;
  }
}







&.page-template-page-family {
  .visit_site{
    position: absolute;
    bottom: 10px;
    left:0;
    right: 0;
    text-align: center;
  }
  .img-responsive{
    margin: 0 auto;
  }
  .family-first .container{
    max-width: 1600px!important;
  }
  .partnercontent3{
    background-color:#e3f0fc;
  }
  .family-first,.family-first-content{
    background-color: #f4faff;
  }
  .partners-banner {
    padding: 70px 15px;
  }
  .family-first-left{
    padding: 40px 15px;
  }
  .partnercontent3-padding {
    .img-responsive {
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
  .bluep{
    color:#17579f;
    padding-top: 20px;
  }
  .partners-small-banner {
    padding: 10px 40px;
  }
  .partnercontent2 {
    padding: 40px 0;
  }
  .partnercontent3-padding {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .partner-tab {
    .nav-tabs {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      li {
        width: 100%;
        margin-bottom: 1px;
      }
      a {
        padding: 15px;
      }
    }
  }
  .partnercontent2 {
    h2 {
      font-size: 29px;
      line-height: 34px;
      color: #17579f;
    }
  }
  @media (min-width: 480px) {

  }

  @media (min-width: 768px) {
    .partner-tab {
      .nav-tabs {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: stretch;
        li {
          width: 33.33333333%;
          margin-bottom: 0px;
        }
        a {
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          padding: 35px;
          height: 100%;
          span {
            display: inline-block;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }


  @media (min-width: 992px) {
    .img-responsive{
      margin: 0;
    }
    .family-first{
      background-color: #cfe5fa;
    }
    .family-first-content{
      .row{
        display: flex;
        flex-direction: row;
        align-content: stretch;
        align-items: stretch;
        width: 100%;
      }

      max-width: 1635px;
      width: 100%;
    }
    .partnercontent3-padding {
      .img-responsive {
        margin: auto;
      }
    }
    .partners-banner {
      padding: 70px;
    }

    .partners-small-banner {
      width: 100%;
      padding: 40px;
    }
    .partnercontent3 {
      h2 {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .partnercontent3-padding {
      display: flex;
      flex-direction: row;
      align-items: center;
      .col-md-5 {
        align-self: flex-start;
      }
    }
    .partnercontent3-padding:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
  }

  @media (min-width: 1200px) {
    .family-first-left{
      padding: 50px;
    }
    .partnercontent2 {
      padding: 80px;
    }
    .partnercontent3-padding {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
}
