//.contactUsPage .banner__info h3::after{
//  display: none;
//}
//.contactUsPage .banner__info h3{
//  margin-top: 60px;
//}
.contactUsPage .banner::before{
  background: linear-gradient(28deg, #1e5ba1 3%, #008591 100% );
}
.contact{
  margin-bottom: 30px;
}
.contact__container{
  //max-width: 1270px;
  //margin: auto;
}
.contact__info{
  display: flex;
  justify-content: space-between;
}
.contact__map{
  height: 500px;
  width: 50%;
}
.contact__items{
  width: 30%;
}
.contact__item{
  margin-bottom: 40px;
  padding-left: 25px;
}
.contact__title{
  font-family: ff-tisa-sans-web-pro;
  font-size: 26px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 40px;
}
.contact__item h5{
  font-family: ff-tisa-sans-web-pro;
  font-size: 18px;
  color: #1e5ba1;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 12px;
  margin-bottom: 15px;
  position: relative;
}
.contact__item h5::after{
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ebebeb;
}
.contact__item--text a,
.contact__item--text p{
  font-family: 'Open Sans';
  font-size: 16px;
  color: #414141;
  line-height: 18px;
  text-decoration: none;
  margin-bottom: 8px;
  transition: .3s;
  display: block;
  font-weight: 600;
}
.contact__item--text a{
  display: inline-block;
  word-break: break-all;
  &:first-child {
    display: inline-block;
  }
}
.contact__item--text a[href^='http']{
  line-height: 26px;
  margin-bottom: 12px;
}
.contact__item--text span{
  display: block;
}
.contact__item--text a:hover{
  color: #64afed;
}
.contact__bottom a{
  display: block;
}
.contact__item--text br{
  display: none;
}
.contact__item--text a[href^='http'] br{
  display: block;
}
.contact__item--text span{
  display: block;
}
.contact__top{
  position: relative;
  overflow: hidden;
  margin-bottom: 80px;
}
.contact__map .googlemap{
  position: absolute;
  width: 55%;
  right: 0;
  left: 45%;
  top: 70px;
  bottom: 70px;
}
.contact__form{
  width: 50%;
}
.contact__form h6{
  font-family: 'Open Sans';
  font-size: 20px;
  color: #1e5ba1;
  line-height: 30px;
  margin-bottom: 50px;
  font-weight: normal;
}
.contact__form label{
  display: block;
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
}
.contact__form label span {
  display: block;
}
.contact__form input,
.contact__form textarea{
  border: none;
  border-bottom: 2px solid #ebebeb;
  width: 100%;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 37px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}
.contact__form textarea {
  padding-top: 6px;
  height: 60px;
  resize: vertical;
}
.contact__form .placeholder{
  position: absolute;
  font-family: ff-tisa-sans-web-pro;
  font-size: 18px;
  color: #414141;
  font-weight: 700;
  line-height: 32px;
  top: -5px;
  transition: .3s;
  background-color: #ffffff;
}
.contact__form .inputs_wrap{
  display: flex;
  justify-content: space-between;
  > span.wpcf7-form-control-wrap {
    width: 100%;
    margin-bottom: 25px;
  }
  &.two {
    label {
      width: 47%;
      &:first-of-type {
        width: 48%;
      }
    }
  }
  select {
    background-image: url("../images/form-select-arrow.svg");
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 30px;
    &::-ms-expand {
      display: none;
    }
  }
}
.contact__form .inputs_wrap label:first-of-type{
  width: 40%;
}
.contact__form .inputs_wrap label{
  width: 25%;
}
.contact__form .inputs_wrap select{
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #ebebeb;
  height: 37px;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  font-family: ff-tisa-sans-web-pro;
  font-size: 18px;
  color: #414141;
  font-weight: 700;
}
.contact__form .inputs_wrap select.optionClass,
.contact__form input,
.contact__form textarea{
  font-family: "Open Sans";
  font-size: 16px;
  color: #414141;
  font-weight: normal;
}
.contact__form .inputs_wrap select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.contact__form .inputs_wrap select option{
  font-size: 14px;
  font-family: 'Open Sans';
}
.contact__form .for_submit{
  display: flex;
  justify-content: space-between;
  @media (max-width: 1149px) and (min-width: 992px) {
    display: block;
    .contactBtn {
      margin-left: 0;
      margin-top: 25px;
    }
  }
  @media (max-width: 767px) {
    .contactBtn {
      margin-left: 0;
    }
  }
}
.contact__form .contactBtn{
  width: 220px;
  background-color: #1e5ba1;
  position: relative;
  border: none;
  font-family: 'Open Sans';
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  padding-right: 25px;
  margin-top: 0;
  @media (max-width: 991px) {
    margin-left: 0;
    margin-top: 0;
  }
}
.contact__form .contactBtn:hover{
  background-color: #073f8c;
}
.contact__form .contactBtn::before,
.contact__form .contactBtn::after{
  content: '';
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
input.wpcf7-not-valid {
  border-bottom: 2px solid red;
}
.contact__form .contactBtn::before{
  width: 17px;
  height: 2px;
  background-color: #fff;
}
.contact__form .contactBtn::after{
  width: 14px;
  height: 14px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  background-color: transparent;
  transform: translateY(-50%) rotate(45deg);
}

.wpcf7-not-valid-tip,
.wpcf7-response-output{
  margin-top: 5px;
  font-size: 12px;
  position: relative;
  font-family: 'Open Sans';
}

.contact__form ::-webkit-input-placeholder {font-family: ff-tisa-sans-web-pro;font-size: 18px;color: #414141;transition: linear .3s;opacity: 1;font-weight: 700;}
.contact__form ::-moz-placeholder {font-family: ff-tisa-sans-web-pro;font-size: 18px;color: #414141;transition: linear .3s;opacity: 1;font-weight: 700;}
.contact__form :-ms-input-placeholder {font-family: ff-tisa-sans-web-pro;font-size: 18px;color: #414141;transition: linear .3s;opacity: 1;font-weight: 700;}
.contact__form :-moz-placeholder {font-family: ff-tisa-sans-web-pro;font-size: 18px;color: #414141;transition: linear .3s;opacity: 1;font-weight: 700;}

.contact__form input:focus::-webkit-input-placeholder,
.contact__form textarea:focus::-webkit-input-placeholder{
  transform: translateX(-200px);
}
.contact__form input:focus::-moz-placeholder,
.contact__form textarea:focus::-moz-placeholder{
  transform: translateX(-200px);
}
.contact__form input:focus:-ms-input-placeholder,
.contact__form textarea:focus:-ms-input-placeholder{
  transform: translateX(-200px);
}
.contact__form input:focus:-moz-placeholder,
.contact__form textarea:focus:-moz-placeholder{
  transform: translateX(-200px);
}



@media (min-width: 992px) {
  .contactUsPage .banner__info{
    min-height: 0;
    height: 425px!important;
  }
}

@media (max-width: 991px) {
  .contact__info{
    display: block;
  }
  .contact__items,
  .contact__map,
  .contact__form{
    width: 100%;
  }
  .contact__map{
    position: relative;
  }
  .contact__map .googlemap{
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

@media (max-width: 767px) {
  .contact__form .for_submit{
    display: block;
  }
  .for_submit .wpcf7-form-control-wrap{
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .contact__item{
    padding-left: 0;
  }
}